import { POST } from "../API"

export const getUsersAPI = (
    page,
    onSuccess,
    onError
) => {
    const data = {
        page: page
    }

    POST(`/api/v1/administration/users`, data, onSuccess, onError, true)
}