import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as ArrowRight } from '../../Assets/icons/forwardArrow.svg'
import NoAvatarIcon from '../../Assets/icons/unsetAvatar.svg'
import DeleteConfirmationView from '../DashboardComponents/DeleteConfirmationView'
import { createSearchParams, useNavigate } from 'react-router-dom'

const UserElement = ({ avatar, username, user, handleRemove, handleBlockUser, handleBlockWithConfirmation }) => {

    const navigate = useNavigate();

    //TODO-uncomment when change user action
    // const goToUserConversations = (user_id) => {

    //     let searchParams = createSearchParams({
    //         userId: user_id
    //     }).toString()

    //     navigate({
    //         pathname: "/conversations",
    //         search: searchParams
    //     })
    // }

    return (
        <ElementWrapper >
            <DeleteWrap>
                <DeleteConfirmationView
                    item={user.id}
                    handleRemoveItem={handleRemove}
                />


            </DeleteWrap>
            {/* TODO-remove when change user action */}
            {/* <Wrapper onClick={() => goToUserConversations(user.id)} blocked={user.blocked}> */}
            <Wrapper >
                <UserWrap>
                    <AvatarWrapper>
                        <img src={avatar ? avatar : NoAvatarIcon} alt='user' /> :
                    </AvatarWrapper>
                    <NameWrap>
                        <h1>{username}</h1>
                    </NameWrap>
                    <RatingWrapper>
                        {user.rating && <p>(Translation rating - {parseFloat(user.rating).toFixed(2)})</p>}
                    </RatingWrapper>
                </UserWrap>
                <GoToWrapper>

                    {user.blocked ?
                        <BlockBtn onClick={() => handleBlockUser(user.id, false)}>Unblock User</BlockBtn> :
                        <DeleteConfirmationView
                            item={user.id}
                            handleRemoveItem={handleBlockWithConfirmation}
                            variant={"block-user"}
                        />
                    }

                    {/* //TODO-remove when change user action */}
                    {/* <GoToBtn >
                        <ArrowRight />
                    </GoToBtn> */}
                </GoToWrapper>
            </Wrapper>

        </ElementWrapper>
    )
}

export default UserElement
const ElementWrapper = styled.div`
display:flex;
align-items:center;
padding:15px 35px;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
justify-content:space-between;
&:last-child{
    border-bottom:none;
}

`
const UserWrap = styled.div`
display:flex;
align-items:center;
gap:10px;
`
const NameWrap = styled.div`
display:flex;
align-items:center;
h1{
color:${colors.dashBoard.elementColor};
font-size: 16px;
font-weight: 500;
line-height: 22px;
}
`

const DeleteWrap = styled.div`
display:flex;
align-items:center;
margin-right:20px;
`
const RemoveButton = styled.button`
border:none;
background:none;
cursor:pointer;
`
const AvatarWrapper = styled.div`

display:flex;
align-items:center;
width:28px;
height:28px;
border-radius:100%;
border:1px solid ${colors.dashBoard.purple};
overflow:hidden;
img{
    object-fit:cover;
height:100%;
width:100%;
}
`
const GoToWrapper = styled.div`
display:flex;
align-items:center;
`
const GoToBtn = styled.button`
border:none;
background:none;
display:flex;
align-items:center;
color:white;
cursor:pointer;
svg{
    width:12px;
    height:14px;
}
`
const Wrapper = styled.div`
display:flex;
width:100%;
justify-content:space-between;
// cursor:pointer;
opacity:${props => props.blocked ? "0.5" : "1"};
`

const BackBtn = styled.button`
  display: flex;
  align-items: center;
  border: none;
  color: ${colors.conversationView.purple};
  background: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;

  svg{
   color: ${colors.conversationView.purple};
  }
`;
const BlockBtn = styled(BackBtn)`
color:white;
  background: ${colors.conversationView.errorColor};
`;
const RatingWrapper = styled.div`
display:flex;
align-items:center;
p{
    font-size:14px;
    color:${colors.white};
}
`