import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import OutlineButton from '../Generic Components/OutlineButton'
import DefinePromptModal from './DefinePromptModal'
import { useLlmService } from '../../Context/LLMContext'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'

const LLMModelsOption = ({ setIsLoading }) => {

    const [editPromptModal, setEditPromtModal] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const { models, setPrompt, getAllModels, setTranslationParams } = useLlmService();

    const handleEditPrompt = (model) => {
        setEditPromtModal(true)
        setSelectedModel(model)
    }

    const handleCloseModal = () => {
        setEditPromtModal(false)
    }

    const handleSavePrompt = (id, text) => {
        setIsLoading(true)
        setPrompt(
            id, text,
            (response) => {
                notifySuccess("Prompt edited")
                getAllModels((response) => { }, (error) => { })
                setSelectedModel(response.data.model)
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                notifyError("Something wrong")
                setIsLoading(false)
            }
        )
    }

    const handleSaveParams = (id, temperature, top_k, top_p, response_length) => {
        setIsLoading(true)
        let temp = parseFloat(temperature);
        let topk = parseInt(top_k);
        let topp = parseFloat(top_p);
        let respL = parseInt(response_length);

        if (temperature == "") {
            temp = null
        }
        if (top_k == "") {
            topk = null
        }
        if (top_p == "") {
            topp = null
        }
        if (response_length == "") {
            respL = null
        }


        setTranslationParams(
            id, temp, topk, topp, respL,
            (response) => {
                notifySuccess("Params edited")
                getAllModels((response) => { }, (error) => { })
                setSelectedModel(response.data.model)
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                notifyError("Something wrong")
                setIsLoading(false)
            }
        )
    }

    return (
        <Container>
            <Group>
                {models.map((model) => (
                    <Line key={model.id}>
                        <ReadOnlyField>{model.name}</ReadOnlyField>
                        <OutlineButton
                            onClick={() => handleEditPrompt(model)}
                            style={{ maxWidth: "180px" }}
                            text={"Edit Prompt"}
                        />
                    </Line>

                ))}

            </Group>

            {editPromptModal &&
                <DefinePromptModal
                    selectedModel={selectedModel}
                    handleCloseModal={handleCloseModal}
                    handleSavePrompt={handleSavePrompt}
                    handleSaveParams={handleSaveParams}
                />}
        </Container>
    )
}

export default LLMModelsOption
const Container = styled.div`
height:100%;
display:flex;
flex-direction:column;
gap:30px;

`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:20px;
`
const Line = styled.div`
display:flex;
align-items:center;
gap:30px;
`
const ReadOnlyField = styled.div`
display:flex;
align-items:center;
border-radius:10px;
background:${colors.dashBoard.headerBg};
padding:11px 17px;
color:${colors.dashBoard.elementColor};
max-width:350px;
width:100%;
`