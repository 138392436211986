import { GET } from "../API"


let source;
export const getNumberOfUsersAPI = (
    onSuccess,
    onError
) => {

    source = GET(`/api/v1/administration/number_of_users`, onSuccess, onError, true, source)
}