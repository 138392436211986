
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { notifyError } from '../../Helpers/Notifications'
import { getNumberOfUsersAPI } from '../../API/analytics/getNumberOfUsers'
import { getAverageRatingAPI } from '../../API/analytics/getAverageRating'

const StatisticsElement = ({ title, value }) => {
    return (
        <StatisticContainer>
            <h1>{title}</h1>
            <h2>{value}</h2>
        </StatisticContainer>
    )
}


const AalyticsOptionPage = ({ setIsLoading }) => {

    const [averageTranslationRating, setAverageTranslationRating] = useState(0);
    const [numberOfUsers, setNumberOfUsers] = useState(0);

    const getUsersNumber = () => {
        setIsLoading(true);
        getNumberOfUsersAPI(
            function (response) {
                let numberOfUsers = response.data.number_of_users;
                setNumberOfUsers(numberOfUsers)
                setIsLoading(false);
            },
            function (error) {
                notifyError("Something wrong");
                console.log(error)
                setIsLoading(false);
            }

        )

    }
    const getAverageRating = () => {
        setIsLoading(true);
        getAverageRatingAPI(
            function (response) {
                let averageRating = response.data.average_rating;
                setAverageTranslationRating(parseFloat(averageRating).toFixed(2))
                setIsLoading(false);
            },
            function (error) {
                notifyError("Something wrong");
                console.log(error)
                setIsLoading(false);
            }

        )

    }

    useEffect(() => {
        getUsersNumber();
        getAverageRating();
    }, [])
    return (
        <AalyticsOptionPageContainer>

            <Title><h1>Statistics</h1></Title>
            <StatiscticsGroup>
                <StatisticsElement
                    title={"Number Of users"}
                    value={numberOfUsers}
                />
                <StatisticsElement
                    title={"Average Translation Rating"}
                    value={averageTranslationRating}
                />
            </StatiscticsGroup>

        </AalyticsOptionPageContainer>
    )
}

export default AalyticsOptionPage

const AalyticsOptionPageContainer = styled.div`
display:flex;
flex-direction:column;
gap:35px;
padding:35px 50px;
background:${colors.dashBoard.bg};
height:100vh;
position:relative;
`

const Title = styled.div`
h1{
font-size: 25px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
`
const StatiscticsGroup = styled.div`
display:flex;
flex-direction:column;
overflow:hidden;
border-radius:10px;
background:${colors.dashBoard.elementBg};
width:fit-content;

`
const StatisticContainer = styled.div`
display:flex;
flex-direction:column;
gap:15px;
border-bottom: 1px solid rgba(54, 62, 81, 0.5);
padding:20px 30px;
&:last-child{
    border-bottom:none;
}
h1{
color:white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
}
h2{
    color:${colors.dashBoard.purple};
font-size: 30px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
`