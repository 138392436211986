
import React, { useEffect, useState } from 'react'

import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from '../../Assets/icons/closeIcon.svg'
import { Switch } from '@mui/material'
import CustomDatePicker from '../DashboardComponents/CustomDatePicker'
import { useDropzone } from 'react-dropzone'
import { useMessageToUsersService } from '../../Context/MessageToUsersContext'
import moment from 'moment'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'
import styled from 'styled-components'
import { MediaPlayer, MediaProvider } from '@vidstack/react'
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import FileAttachmentItem from './FileAttachmentItem'
import { IMAGE, VIDEO } from '../../Constants/OptionsConstants'


const TextComponent = ({ setMessageValue, messageValue }) => {
    return (
        <Group>
            <Title><h1>Create a message</h1></Title>
            <CustomTextArea
                name='message'
                placeholder='Start writing...'
                onChange={(e) => setMessageValue(e.currentTarget.value)}
                value={messageValue}
            />
        </Group>
    )
}


const parseMediaType = (mediaType) => {
    if (!mediaType) {
        return
    }
    const parsedType = mediaType.split("/");

    return parsedType[0]
}

const handleAcceptedFiles = (file, setMedia) => {

    const fileType = file.type.split("/")

    if (fileType[0] == IMAGE || fileType[0] == VIDEO) {
        if (fileType[0] == VIDEO) {
            setMedia(Object.assign({
                type: "video/mp4",
                file: file,
                src: URL.createObjectURL(file)
            }))
        } else
            setMedia(Object.assign({
                type: file.type,
                file: file,
                src: URL.createObjectURL(file)
            }))
    } else {

        setMedia(Object.assign({
            type: file.type,
            file: file,
            src: null
        }))
    }

}


const MediaComponent = ({ setMedia, media, setMessageValue, messageValue }) => {
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        maxFiles: 1,
        accept: {
            '*': [],

        },

        onDrop: acceptedFiles => {
            handleAcceptedFiles(acceptedFiles[0], setMedia);
        }
    })

    const previewUploadedItem = (media) => {
        if (!media || !media.type) {

            return <TextPrev>
                <p><span>Click to add</span> <br />or drop here.</p>
            </TextPrev>
        }



        if (parseMediaType(media?.type) === IMAGE) {

            return <Thumb>
                <img src={media.src} alt='preview' />
            </Thumb>
        }

        if (parseMediaType(media?.type) === VIDEO) {

            return <VideoPreview>
                <MediaPlayer
                    load='visible'
                    posterLoad='visible'
                    controls
                    playsInline
                    src={media}
                >
                    <MediaProvider />
                    <DefaultVideoLayout thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt" icons={defaultLayoutIcons} />
                </MediaPlayer>
            </VideoPreview>
        }

        if (parseMediaType(media?.type) === "application") {
            return <FileAttachmentItem
                file={media.file}
            />
        }


    }


    return (
        <Group>
            <Title><h1>Add Media</h1></Title>
            <MediaWrapper wide={parseMediaType(media.type) === VIDEO || parseMediaType(media.type) === "application"}>
                <section>
                    <div {...getRootProps()} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <input {...getInputProps({
                            multiple: false
                        })} />
                        {previewUploadedItem(media)}
                    </div>
                </section>

            </MediaWrapper>
            <CustomTextArea
                name='message'
                placeholder='Start writing...'
                onChange={(e) => setMessageValue(e.currentTarget.value)}
                value={messageValue}
            />
        </Group >
    )
}



const AddNewMessageModal = ({ handleCloseModal, shouldRefresh, setIsLoading }) => {
    const { send_message_to_old_users, add_new_user_message } = useMessageToUsersService();

    const TEXT = "text"
    const MEDIA = "media"

    const [option, setOption] = useState(TEXT);
    const [messageValue, setMessageValue] = useState("");
    const [image, setImage] = useState(null);

    const [media, setMedia] = useState({
        type: null,
        file: null,
        src: null
    }
    );

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [sendBtnIsDisabled, setSendBtnIsDisabled] = useState(true)

    const checkReadyToSend = () => {
        if (option === TEXT) {
            if (messageValue != "" && startDate && endDate) {
                setSendBtnIsDisabled(false)
            } else {
                setSendBtnIsDisabled(true)
            }
        }


        if (option === MEDIA) {
            if (media != null && startDate && endDate) {
                setSendBtnIsDisabled(false)
            } else {
                setSendBtnIsDisabled(true)
            }
        }
    }

    const handleSwitchChange = (e) => {
        let checked = e.currentTarget.checked;
        setMessageValue("");
        setImage(null);
        if (checked) {
            setOption(MEDIA)
        } else {
            setOption(TEXT)
        }
    }

    const displayTextOrMedia = () => {
        var content =
            <TextComponent
                setMessageValue={setMessageValue}
                messageValue={messageValue}
            />

        if (option === MEDIA) {
            content =
                <MediaComponent
                    messageValue={messageValue}
                    setMessageValue={setMessageValue}
                    setMedia={setMedia}
                    media={media}
                />
        }
        return content
    }


    const handleSendText = () => {
        setIsLoading(true)
        let start_date = moment(startDate).startOf("day").toISOString();
        let end_date = moment(endDate).endOf("day").toISOString();

        let dataObject = new FormData();
        dataObject.append("original_message", messageValue);
        dataObject.append("device_sent_date", moment().toISOString());
        dataObject.append("registration_start_date", start_date)
        dataObject.append("registration_end_date", end_date)

        send_message_to_old_users(
            dataObject,
            function (response) {
                notifySuccess("New text sent");
                shouldRefresh.current = true;
                setIsLoading(false)
                handleCloseModal();

            },
            function (error) {
                setIsLoading(false)
                notifyError("Something wrong...")
            },
        )
    }


    const handleSendMedia = (type, file, text, thumbnail) => {
        setIsLoading(true)
        let start_date = moment(startDate).startOf("day").toISOString();
        let end_date = moment(endDate).endOf("day").toISOString();

        let dataObject = new FormData();
        dataObject.append("original_message", "");
        dataObject.append("device_sent_date", moment().toISOString());
        dataObject.append("registration_start_date", start_date)
        dataObject.append("registration_end_date", end_date)

        if (type === IMAGE) {
            dataObject.append(IMAGE, file);
        }

        if (type === VIDEO) {
            dataObject.append("video", file);
            dataObject.append("video_thumbnail", thumbnail);
        }
        if (type === "application") {
            dataObject.append("document", file);
        }

        if (text.length > 0) {
            dataObject.append("original_message", text);
        }


        send_message_to_old_users(
            dataObject,
            function (response) {
                notifySuccess("Media sent");
                shouldRefresh.current = true;
                handleCloseModal();
                setIsLoading(false)
            },
            function (error) {
                setIsLoading(false)
                notifyError("Something wrong...")
            },
        )
    }

    const generateVideoThumbnail = (file) => {

        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement(VIDEO);

            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };

    const generateThumbnail = async (file) => {
        const thumbnail = await generateVideoThumbnail(file);
        return thumbnail
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleSendVideo = async (media, message) => {
        await generateThumbnail(media).then(
            (thumbnail) => {
                let thumb = dataURLtoFile(thumbnail, "thumbnail")
                handleSendMedia(VIDEO, media, message, thumb);
            }
        );

    }

    const handleSend = (type, media, message) => {
        if (type.includes(VIDEO)) {
            handleSendVideo(media, message)

        }
        if (type.includes(IMAGE)) {
            handleSendMedia(IMAGE, media, message, null);
        }

        if (type.includes("application")) {
            handleSendMedia("application", media, message, null);
        }

    }

    const sendMessage = () => {
        if (option === TEXT) {
            handleSendText();
        }
        if (option === MEDIA) {
            handleSend(media.type, media.file, messageValue);
        }
    }

    useEffect(() => {
        checkReadyToSend();
    }, [option, messageValue, image, startDate, endDate])

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleCloseModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <FullScreenContainer>
            <Card>
                <CloseBtn >
                    <CloseIcon onClick={() => handleCloseModal()} />
                </CloseBtn>
                <SwitchWrapper>
                    <p style={option === TEXT ? { opacity: "1" } : {}}>Text</p>
                    <Switch

                        onChange={(e) => handleSwitchChange(e)}
                        sx={{
                            padding: "0 6px",
                            alignItems: "center",
                            span: {
                                color: "white",
                            },

                            "& .MuiSwitch-track": {
                                height: "26px",
                                width: "100%",
                                alignItems: "center",
                            },
                            "& .MuiSwitch-thumb": {

                            },
                            "& .MuiSwitch-switchBase.Mui-checked ": {
                                transform: "translateX(20px)",
                                color: "rgba(248, 248, 248, 1)"
                            },
                            "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                borderRadius: "12px",
                                opacity: "1",
                                backgroundColor: 'rgba(112, 105, 253, 1)',

                            },
                            "& .MuiSwitch-switchBase+.MuiSwitch-track": {
                                borderRadius: "12px",
                                opacity: "1",
                                backgroundColor: 'rgba(112, 105, 253, 1)',
                            },

                        }}
                    />
                    <p style={option === MEDIA ? { opacity: "1" } : {}}>Media</p>
                </SwitchWrapper>
                {displayTextOrMedia()}
                <Group>
                    <Title><h1>Add date</h1></Title>
                    <DateWrapper>
                        <DateGroup>
                            <p>Start date</p>
                            <CustomDatePicker
                                showIcon
                                value={startDate}
                                setValue={(date) => setStartDate(date)}
                            />
                        </DateGroup>

                        <DateGroup>
                            <p>End Date</p>
                            <CustomDatePicker
                                showIcon
                                value={endDate}
                                setValue={(date) => setEndDate(date)}
                            />
                        </DateGroup>

                    </DateWrapper>
                </Group>
                <Group style={{ marginLeft: "auto", marginTop: "auto" }}>
                    <SendBtn onClick={() => sendMessage()} disabled={sendBtnIsDisabled}>
                        Send
                    </SendBtn>
                </Group>
            </Card>

        </FullScreenContainer>
    )
}

export default AddNewMessageModal
const FullScreenContainer = styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100%;
background:rgba(0,0,0,0.3);
z-index:5;
display:flex;
justify-content:center;
align-items:center;
`
const Card = styled.div`
display:flex;
flex-direction:column;
padding:55px;
border-radius:12px;
gap:50px;
background:${colors.newChatModalBg};
position:relative;
min-width:600px;
min-height:700px;
`

const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
position:absolute;
top:20px;
right:20px;
z-index:2;
font-size:20px;
font-weight:600;
color:${colors.white};

`

const SwitchWrapper = styled.div`
display:flex;
align-items:center;
p{
    color:white;
    opacity:0.3;
font-size: 16px;
font-weight: 500;
line-height: 24px;
}


`

const Title = styled.div`
h1{
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
h2{
    font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:30px;

`
const CustomTextArea = styled.textarea`
width:100%;
height:110px;
padding:16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
color:${colors.white};
outline:none;
resize:none;
border:none;
`
const DateWrapper = styled.div`
display:flex;
gap:40px;

`
const DateGroup = styled.div`
display:flex;
flex-direction:column;
gap:10px;
min-width:250px;
p{
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${colors.white};
}
`
const SendBtn = styled.button`
font-size: 16px;
font-weight: 600;
line-height: 22px;
padding:11px 50px;
background:${colors.dashBoard.purple};
color:${colors.white};
border:none;
border-radius:10px;
cursor:pointer;
width:fit-content;
&:disabled{
    opacity:0.3;
}
`
const MediaWrapper = styled.div`
display:flex;
height:200px;

width:${props => props.wide ? "400px" : "200px"};
border:1px dashed ${colors.dashBoard.inputBg};
section{
    overflow:hidden;
    position:relative;
    width:100%;
    height:100%;
}
cursor:pointer;
`
const Thumb = styled.div`
height:100%;
width:100%;
display:flex;

img{
    height:100%;
    width:100%;
object-fit:cover;
}

`
const TextPrev = styled.div`
p{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color:${colors.white};
}
span{
  color:${colors.dashBoard.purple}  ;
}
`
const VideoPreview = styled.div`
display:flex;
height:100%;
width:100%;
img{
    height:100%;
    width:100%;
object-fit:cover;
}
`