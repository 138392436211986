import { GET } from "../../API";



let source;
export const getNewUserMessagesAPI = (
    onSuccess,
    onError,
) => {

    source = GET(`/api/v1/administration/emotii_chat/new_user_messages`, onSuccess, onError, true, source)
}