
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import Select from 'react-select'
import { ReactComponent as RemoveIcon } from "../../Assets/icons/remove-icon.svg"
import NewPairModal from './NewPairModal'
import PrimaryButton from '../Generic Components/PrimaryButton'
import { useLlmService } from '../../Context/LLMContext'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'
import { mapModelResponseForSelectMenu } from '../../Helpers/LLMHelper'

const LLMLanguagesOption = ({ setIsLoading }) => {

    const { models, translationPairs, getTranslationPairs, getAllModels, setDefaultModel, addTranslationPair, deleteTranslationPair } = useLlmService();

    const [defaultTranslator, setDefaultTranslator] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectOptions, setSelectedOptions] = useState([]);

    const [pairs, setPairs] = useState([])

    const handleRemoveLanguage = (id) => {
        handleDeletePair(id)
    }


    const handleCloseModal = () => {
        setIsModalOpen(false)
    }
    const handleOpenNewPairModal = () => {
        setIsModalOpen(true)
    }

    const loadTranslationPairs = () => {
        getTranslationPairs(() => { }, () => { })
    }

    const handleAddNewPair = (from, to, modelId) => {
        addTranslationPair(
            from,
            to,
            modelId,
            (response) => {
                setIsModalOpen(false)
                loadTranslationPairs();
                notifySuccess("Pair added")
            },
            (error) => {
                notifyError("Something wrong")
                console.log(error)
            }
        )
    }

    const handleDeletePair = (id) => {
        deleteTranslationPair(
            id,
            (response) => {
                loadTranslationPairs();
                notifySuccess("Pair deleted")
            },
            (error) => {
                notifyError("Something wrong")
                console.log(error)
            }
        )
    }


    const customStyles = {
        container: (provided) => ({
            ...provided,
            "&:focus-visible": {
                outline: "none",
            },
            maxWidth: "300px",

        }),

        control: (provided) => ({
            ...provided,
            background: colors.dashBoard.headerBg,
            border: "none",
            borderRadius: "10px",
            outline: "none",
            "&:hover": {
                borderColor: "none",
            },

        }),

        singleValue: (provided) => ({
            ...provided,
            fontSize: "16px",
            fontWeight: "500",
            color: colors.dashBoard.elementColor,

        }),
        menuList: (provided) => ({
            ...provided,
            background: colors.dashBoard.headerBg,

        }),

        option: (provided, { isSelected }) => ({
            ...provided,
            background: colors.dashBoard.headerBg,
            color: isSelected ? colors.dashBoard.purple : colors.dashBoard.elementColor,
            "&:hover": {
                background: colors.dashBoard.inputBg,
            },


        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    }

    const handleSetDefaultModel = (model) => {
        setDefaultModel(
            model.id,
            (response) => {
                getAllModels(() => { }, () => { });
                notifySuccess("Default Model Changed")
            },
            (error) => {
                notifyError("Something wrong")
                console.log(error)
            }

        )
    }


    useEffect(() => {

        let mapped = mapModelResponseForSelectMenu(models);

        let defaultTranslator = mapped.find((item) => item.is_default)
        setSelectedOptions(mapped);
        if (defaultTranslator) {
            setDefaultTranslator(defaultTranslator)
        }

    }, [models])

    useEffect(() => {
        setPairs(translationPairs)
    }, [translationPairs])

    return (
        <>
            <Container>
                <Title>
                    Default Translator for Undefined Pairs
                </Title>

                <Group>
                    <GroupTitle>
                        Model
                    </GroupTitle>
                    <Select
                        styles={customStyles}
                        value={defaultTranslator}
                        options={selectOptions}
                        onChange={(e) => handleSetDefaultModel(e)}
                    />
                </Group>
                <LineSeparator />

                <Title>
                    Edit Languages
                </Title>


                <LanguagesListWrapper>
                    <Group>
                        <GroupTitle>
                            Languages
                        </GroupTitle>

                        <ReadonlyFieldWrapper>
                            {pairs.map((pair, index) => (
                                <ReadOnlyField key={index}>
                                    <p>{pair.from} > {pair.to} </p>
                                </ReadOnlyField>

                            ))}

                        </ReadonlyFieldWrapper>

                    </Group>
                    <Group>
                        <GroupTitle>
                            Model
                        </GroupTitle>
                        <ReadonlyFieldWrapper>
                            {pairs.map((pair, index) => (
                                <Line key={index}>
                                    <ReadOnlyField>
                                        <p>{pair.model.name}</p>
                                    </ReadOnlyField>
                                    <RemoveBtn onClick={() => handleRemoveLanguage(pair.id)}>
                                        <RemoveIcon />
                                    </RemoveBtn>
                                </Line>

                            ))}

                        </ReadonlyFieldWrapper>
                    </Group>
                </LanguagesListWrapper>
                <PrimaryButton
                    onClick={() => handleOpenNewPairModal()}
                    style={{ maxWidth: "540px" }}
                    text={"Add New Pair"}
                >

                </PrimaryButton>

            </Container>
            {isModalOpen &&
                <NewPairModal
                    handleSavePair={handleAddNewPair}
                    handleCloseModal={handleCloseModal}
                />
            }</>
    )
}

export default LLMLanguagesOption

const Container = styled.div`
height:100%;
display:flex;
flex-direction:column;
gap:30px;
`
const Title = styled.div`

font-size: 25px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};

`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:15px;
`
const GroupTitle = styled.p`
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${colors.dashBoard.elementColor};
`
const LineSeparator = styled.div`
margin:20px 0;
width:100%;
min-height:1px;
background:rgba(54, 62, 81, 0.5);

`

const LanguagesListWrapper = styled.div`
display:flex;
gap:35px;
width:fit-content;
`
const ReadOnlyField = styled.div`
display:flex;
align-items:center;
border-radius:10px;
background:${colors.dashBoard.headerBg};
padding:11px 17px;
color:${colors.dashBoard.elementColor};
max-width:254px;
min-width:254px;
width:100%;
`

const ReadonlyFieldWrapper = styled.div`
display:flex;
flex-direction:column;
gap:20px;

`
const RemoveBtn = styled.button`
border:none;
background:none;
cursor:pointer;
`
const Line = styled.div`
display:flex;
align-items:center;
gap:25px;
`