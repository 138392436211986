import React, { useEffect } from 'react'
import LoginPage from '../Pages/LoginPage';
import { useAuthService } from '../Context/AuthContext';
import LocalStorage from '../Helpers/LocalStorage';

const PrivateRoute = ({ children }) => {
    const { user } = useAuthService();
    const authToken = LocalStorage.getAuthenticationToken();

    //TODO: implement getUser cu authToken

    if (user === undefined) return <div></div>
    if (user === null) return <LoginPage />
    return (
        <>{children}</>
    )

}

export default PrivateRoute