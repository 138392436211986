import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { ReactComponent as RemoveIcon } from '../../Assets/icons/remove-icon.svg'
import { colors } from "../../Assets/styles/colors";



const DeleteConfirmationView = ({ handleRemoveItem, item, variant }) => {
    const [open, setOpen] = React.useState(false);
    const BLOCK_USER = "block-user"
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        handleRemoveItem(item);
        setOpen(false);
    };

    const ButtonToDisplay = () => {
        let displayItem = (
            <DeleteButton onClick={() => handleClickOpen()}>
                <RemoveIcon />
            </DeleteButton>
        )

        if (variant == BLOCK_USER) {
            displayItem = (
                <BlockBtn onClick={() => handleClickOpen()}>Block User</BlockBtn>
            )
        }


        return (
            displayItem
        )
    }

    const DisplayDialogVariant = () => {
        let displayDialog = (<DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this user?
        </DialogContentText>)
        if (variant == BLOCK_USER) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to block this user?
                </DialogContentText>

            )


        }
        return displayDialog
    }

    return (
        (<div>
            <ButtonToDisplay />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>)


    );
};

export default DeleteConfirmationView;
const DeleteButton = styled.button`
border:none;
background:none;
cursor:pointer;
`


const BackBtn = styled.button`
  display: flex;
  align-items: center;
  border: none;
  color: ${colors.conversationView.purple};
  background: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;

  svg{
   color: ${colors.conversationView.purple};
  }
`;
const BlockBtn = styled(BackBtn)`
color:white;
  background: ${colors.conversationView.errorColor};
`;