import { GET, POST } from "../../API";

let source;

export const getOldUserMessagesAPI = (
    data,
    onSuccess,
    onError,
) => {
    source = POST(`/api/v1/administration/emotii_chat/sent_messages_to_old_users`, data, onSuccess, onError, true, source)
}