
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import MessageReceived from '../ConversationsView/MessageReceived'
import moment from 'moment'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as SendMediaIcon } from '../../Assets/icons/sendMediaIcon.svg'
import { ReactComponent as SendMessageIcon } from '../../Assets/icons/sendMessageButton.svg'
import cloneDeep from 'clone-deep'
import { useMessageToUsersService } from '../../Context/MessageToUsersContext'
import { notifyError } from '../../Helpers/Notifications'
import Message from '../ConversationsView/Message'
import { useContextMenu } from 'react-contexify'
import RightClickMenu from '../ConversationsView/RightClickMenu'
import DateSeparator from '../ConversationsView/DateSeparator'
import FullscreenImage from '../ConversationsView/FullscreenImage'
import InputLine from './InputLine'
import SendFile from './SendFile'
import SendMedia from './SendMedia'


const MessagePageNewUsers = ({ setIsLoading }) => {

    const { get_new_user_message, add_new_user_message, delete_new_user_message } = useMessageToUsersService();
    const bottomRef = useRef();
    const messageListRef = useRef();


    const [messages, setMessages] = useState([]);

    const [deleteItem, setDeleteItem] = useState(null)

    const fileInputRef = useRef();
    const mediaInputFileRef = useRef();
    const messageInputRef = useRef();

    const [inputValue, setInputValue] = useState("");
    const [fileInput, setFileInput] = useState(null)
    const [mediaInput, setMediaInput] = useState(null);

    const [showFullScreenImg, setShowFullScreenImg] = useState(false);
    const [fullScreenImg, setFullScreenImg] = useState(null);
    const [isAttachmentMenuOpen, setIsAttachmentMenuOpen] = useState(false)

    const handleMessageInputChange = (e) => {
        const { value } = e.currentTarget;
        setInputValue(value);
    };

    const handleShowFullScreenImg = (image, messageId, conversationId) => {

        setFullScreenImg({
            image: image,
            messageId: messageId,
            conversationId: conversationId
        })
        setShowFullScreenImg(true)
    }

    const handleCloseFullScreenImg = () => {
        setShowFullScreenImg(false)
        setFullScreenImg(null)
    }

    const MENU_ID = "menu-id";
    const { show } = useContextMenu({
        id: MENU_ID
    });

    function displayRightClickMenu(e, messageId, conversationId) {
        setDeleteItem({
            messageId: messageId,
            conversationId: conversationId
        })
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show({
            event: e,
        });
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSendButton()
        }
    }

    const handleGetMessages = () => {
        setIsLoading(true)
        get_new_user_message(
            function (response) {
                const messages = response.data.new_user_messages;
                setMessages(messages)
                // console.log(messages)
                setIsLoading(false)
            },
            function (error) {
                console.log(error)
                notifyError("Something wrong...")
                setIsLoading(false)
            }
        )
    }

    const createDateSeparators = (messages) => {
        if (!messages) {
            return
        }
        let copy = cloneDeep(messages);
        for (let index = 1; index < copy.length; index++) {
            let prev = copy[index - 1]
            let current = copy[index]

            if (compareDates(prev.device_sent_date, current.device_sent_date)) {
                copy.splice(index, 0, {
                    type: "date-separator",
                    date: prev.device_sent_date
                })
            }
        }

        copy.splice(0, 0, {
            type: "date-separator",
            date: copy[0]?.device_sent_date
        })

        return copy
    }

    const compareDates = (dateA, dateB) => {
        let a = moment(dateA)
        let b = moment(dateB)
        return (a.isBefore(b, "D"))
    }

    const handleSendMessage = () => {
        if (!inputValue.replace(/\s/g, '').length) {
            return
        }

        var dataObj = new FormData();
        dataObj.append("device_sent_date", moment().toISOString());
        dataObj.append("original_message", inputValue);

        add_new_user_message(
            dataObj,
            function (response) {
                // console.log(response);
                setInputValue("");
                handleGetMessages();
            },
            function (error) {
                console.log(error);
                notifyError("Something wrong")
            },
        )
    }

    const handleConvertBitesToMb = (bites) => {
        const Mb = bites / 1000000
        return Mb
    }

    const handleSendMedia = (type, file, text, thumbnail, onSuccess, onError) => {
        console.log("4")
        setIsLoading(true);
        let dataObject = new FormData();
        let nowDate = moment().toISOString();

        dataObject.append("device_sent_date", nowDate);
        dataObject.append("original_message", text);

        if (type === "image") {
            dataObject.append("image", file);
        }

        if (type === "video") {
            dataObject.append("video", file);
            dataObject.append("video_thumbnail", thumbnail);
        }


        add_new_user_message(
            dataObject,
            function (response) {
                onSuccess();
                setIsLoading(false)
                handleGetMessages();

            },
            function (error) {
                onError();
                console.log(error, "error");
                setIsLoading(false);
                if (error.response.status === 413) {
                    notifyError(
                        `File size exceeds the limit. Please ensure the file is less than ${handleConvertBitesToMb(
                            error.response.data.max_size
                        )}MB`
                    );
                } else {
                    notifyError("Something wrong");
                }
            }
        );
    };


    const handleSendFile = (file, text, onSuccess, onError) => {
        setIsLoading(true);
        let dataObject = new FormData();
        let nowDate = moment().toISOString();

        dataObject.append("device_sent_date", nowDate);
        dataObject.append("original_message", "");
        dataObject.append("document", file);

        if (text.length > 0) {
            dataObject.append("original_message", text);
        }

        add_new_user_message(
            dataObject,
            function (response) {
                onSuccess();
                handleGetMessages();
                setIsLoading(false);
            },
            function (error) {
                onError();
                console.log(error, "error");
                setIsLoading(false);
            }
        );
    };



    const handleMediaInputFileChange = (e) => {
        if (!e.target.files) {
            return
        }

        const inputFile = e.target.files[0]
        const fileType = inputFile.type.split("/")
        if (fileType[0] == "image" || fileType[0] == "video") {
            setMediaInput(inputFile)

        } else {
            notifyError("Please select valid file")
            return
        }

    }

    const handleInputFileChange = (e) => {
        if (!e.target.files) {
            return
        }

        const inputFile = e.target.files[0];
        setFileInput(inputFile);

    }

    const resetInputs = () => {
        mediaInputFileRef.current.value = "";
        fileInputRef.current.value = "";
        setInputValue("")
    }

    const handleSendButton = () => {

        handleSendMessage(
            inputValue,
            () => {
                setInputValue("");
            }
        )

    }

    const handleSendMediaButton = (type, file, text, thumbnail) => {
        console.log("3")
        handleSendMedia(type, file, text, thumbnail,
            () => {
                resetInputs()
            },
            () => {
                resetInputs()
            }
        )

    }

    const handleSendFileButton = (file, text) => {

        handleSendFile(file, text,
            () => {
                resetInputs()
            },
            () => {
                resetInputs()
            }

        )

    }



    const handleOpenUploadMedia = () => {
        mediaInputFileRef.current.click()
    }

    const handleOpenUploadFile = () => {
        fileInputRef.current.click()
    }


    const handleDeleteMessage = (messageId) => {
        delete_new_user_message(
            messageId,
            function (response) {
                handleGetMessages();
            },
            function (error) {
                console.log(error);
                notifyError("Something wrong")
            },
        )
    }


    useEffect(() => {
        let element = document.getElementById("messagesList");
        element.scrollTo("top", element.scrollHeight);
    }, [messages])

    useState(() => {
        handleGetMessages();
    }, [])

    return (
        <Container >

            <RightClickMenu
                deleteItem={deleteItem}
                handleDeleteMessage={handleDeleteMessage}
            />

            {showFullScreenImg &&
                <FullscreenImage
                    handleDeletePhoto={handleDeleteMessage}
                    image={fullScreenImg.image}
                    conversationId={fullScreenImg.conversationId}
                    messageId={fullScreenImg.messageId}
                    handleCloseFullScreenImg={handleCloseFullScreenImg}
                />
            }

            <ConversationWrapper id={"messagesList"} ref={messageListRef} >
                {createDateSeparators(messages).map((message, index) => {
                    if (message.id) {
                        return (
                            < Message
                                key={message.id}
                                text={message.original_message}
                                image={message.image}
                                messageId={message.id}
                                sentTime={moment(message.device_sent_date).format("HH:mm")}
                                displayRightClickMenu={displayRightClickMenu}
                                handleShowFullScreenImg={handleShowFullScreenImg}
                                video={message.video}
                                file={message.document}
                            />
                        )

                    } else {
                        return <DateSeparator key={index} date={message.date} />
                    }

                })}
                <div ref={bottomRef}></div>
            </ConversationWrapper>

            <InputLine
                fileInputRef={fileInputRef}
                mediaInputFileRef={mediaInputFileRef}
                messageInputRef={messageInputRef}
                handleMediaInputFileChange={handleMediaInputFileChange}
                inputValue={inputValue}
                setInputValue={setInputValue}
                isAttachmentMenuOpen={isAttachmentMenuOpen}
                setIsAttachmentMenuOpen={setIsAttachmentMenuOpen}
                handleOpenUploadMedia={handleOpenUploadMedia}
                handleOpenUploadFile={handleOpenUploadFile}
                handleSendButton={handleSendButton}
                onKeyDown={handleKeyDown}
                handleInputFileChange={handleInputFileChange}
            />

            {fileInput && (
                <SendFile
                    fileInput={fileInput}
                    setFileInput={setFileInput}
                    handleSendFileButton={handleSendFileButton}
                    fileInputRef={fileInputRef}
                    inputValue={inputValue}
                    handleInputChange={handleMessageInputChange}
                    messageInputRef={messageInputRef}
                />
            )}

            {mediaInput && (
                <SendMedia
                    mediaInput={mediaInput}
                    setMediaInput={setMediaInput}
                    handleSendMedia={handleSendMediaButton}
                    mediaInputFileRef={mediaInputFileRef}
                    inputValue={inputValue}
                    handleInputChange={handleMessageInputChange}
                    messageInputRef={messageInputRef}

                />
            )}



        </Container >

    )
}

export default MessagePageNewUsers



const Container = styled.div`
display:flex;
flex-direction:column;
background:${colors.chatBg};
height:100%;
position:relative;
max-height:calc(100vh - 75px);

`

const ConversationWrapper = styled.div`
height:100%;
width:100%;
overflow:auto;
min-height:calc(100vh - 149px);
display:flex;
flex-direction:column;

`
const MessageInputWrapper = styled.div`
display:flex;
background:${colors.userMenuHeaderBg};
padding:16px;
min-height:74px;
height:74px;
min-height:74px;
max-height:74px;
height:100%;
gap:12px;
`
const SendMediaButton = styled.button`
width:42px;
height:42px;
border-radius:100%;
display:flex;
jusitfy-content:center;
align-items:center;
cursor:pointer;
border:none;
background:none;
`
const SendMessageButton = styled.button`
width:42px;
height:42px;
border-radius:100%;
display:flex;
jusitfy-content:center;
align-items:center;
cursor:pointer;
border:none;
background:none;
&:disabled{
opacity:0.5;
cursor:inherit;
}

`
const MessageInput = styled.input`
width:100%;
border-radius:100px;
padding:10px 16px;
border:none;
background:#D9D9D90D;
color:${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
&:focus{
outline:none;
}
`