
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg"
import Select from 'react-select'
import PrimaryButton from '../Generic Components/PrimaryButton'
import { useLlmService } from '../../Context/LLMContext'
import { mapModelResponseForSelectMenu } from '../../Helpers/LLMHelper'
import { useAuthService } from '../../Context/AuthContext'



const NewPairModal = ({ handleCloseModal, handleSavePair }) => {

    const { models } = useLlmService();
    const { translationLanguages } = useAuthService();
    const [translateFrom, setTranslateFrom] = useState()
    const [translateTo, setTranslateTo] = useState()
    const [model, setModel] = useState()

    const languageOptions = translationLanguages.map((lang) => {
        return {
            ...lang,
            value: lang.name,
            label: lang.name
        }
    })

    const modelOptions = mapModelResponseForSelectMenu(models)

    const customStyles = {
        container: (provided) => ({
            ...provided,
            "&:focus-visible": {
                outline: "none",
            },
            width: "100%",
        }),

        control: (provided) => ({
            ...provided,
            background: colors.dashBoard.headerBg,
            border: "none",
            borderRadius: "10px",
            outline: "none",
            "&:hover": {
                borderColor: "none",
            },

        }),

        input: (provided) => ({
            ...provided,
            color: colors.dashBoard.elementColor,

        }),

        singleValue: (provided) => ({
            ...provided,
            fontSize: "16px",
            fontWeight: "500",
            color: colors.dashBoard.elementColor,

        }),
        menuList: (provided) => ({
            ...provided,
            background: colors.dashBoard.headerBg,

        }),

        option: (provided, { isSelected }) => ({
            ...provided,
            background: colors.dashBoard.headerBg,
            color: isSelected ? colors.dashBoard.purple : colors.dashBoard.elementColor,
            "&:hover": {
                background: colors.dashBoard.inputBg,
            },


        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleCloseModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    return (
        <FullscreenWrapper>
            <Card>
                <CloseBtn onClick={() => handleCloseModal()} >
                    <CloseIcon />
                </CloseBtn>

                <Title>
                    New Pair
                </Title>

                <Group>
                    <GroupTitle>
                        Language
                    </GroupTitle>
                    <Line>
                        <Select
                            placeholder="Choose"
                            styles={customStyles}
                            value={translateFrom}
                            options={languageOptions}
                            onChange={(e) => setTranslateFrom(e)}
                        />
                        <Select
                            placeholder="Choose"
                            styles={customStyles}
                            value={translateTo}
                            options={languageOptions}
                            onChange={(e) => setTranslateTo(e)}
                        />
                    </Line>
                </Group>
                <Group>
                    <GroupTitle>
                        Model
                    </GroupTitle>
                    <Select
                        placeholder="Choose"
                        styles={customStyles}
                        value={model}
                        options={modelOptions}
                        onChange={(e) => setModel(e)}
                    />
                </Group>

                <PrimaryButton
                    onClick={() => handleSavePair(translateFrom.name, translateTo.name, model.id)}
                    text={"Save"}
                />
            </Card>
        </FullscreenWrapper>
    )
}

export default NewPairModal

const FullscreenWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:rgba(18, 10, 10, 0.53);
position:fixed;
top:0;
bottom:0;
left:0;
width:100%;
`
const Card = styled.div`
position:relative;
display:flex;
flex-direction:column;
gap:30px;
padding:50px;
background:${colors.navBar.bg};
borde-radius:10px;
max-width:650px;
width:100%;
border-radius:10px;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:15px;
width:100%;
`
const GroupTitle = styled.p`
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${colors.dashBoard.elementColor};
`
const Line = styled.div`
display:flex;
align-items:center;
gap:25px;
width:100%;
`

const CloseBtn = styled.button
    `
position:absolute;
top:30px;
right:30px;
border:none;
background:none;
cursor:pointer;
`
const Title = styled.h1`
font-size: 25px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};

`