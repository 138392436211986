import { POST } from "../API"
let source;

export const getMessagesForConversationAPI = (
    userId,
    conversationId,
    page,
    onSuccess,
    onError
) => {

    let data = {
        page: page,
    }

    source = POST(`/api/v1/administration/users/${userId}/conversations/${conversationId}/messages`, data, onSuccess, onError, true, source)
}