export const USER_OPTION = "users"
export const LOGS_OPTION = "logs"
export const ANALYTICS_OPTION = "analytics"
export const MESSAGES_OPTION = "messages"
export const CONFIGURATION_OPTION = "configuration"
export const LLM_OPTION = "llm"
export const PROFILE_OPTION = "profile"


export const IMAGE = "image"
export const VIDEO = "video"
export const DOCUMENT = "document"

