
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'
import { getMaxFileSizeAPICall, setMaxFileSizeAPICall } from '../../API/settings/maxFileSize'

const ConfigurationOptionPage = ({ setIsLoading }) => {

    const [mb, setMb] = useState("");

    const handleConvertBitesToMb = (bites) => {
        const Mb = bites / 1000000
        return Mb
    }

    const handleGetMaxFileSize = () => {
        setIsLoading(true)
        getMaxFileSizeAPICall(
            (response) => {
                const Mb = handleConvertBitesToMb(response.data.max_file_size)
                setMb(Mb);
                setIsLoading(false)
            },
            (error) => {
                notifyError("Something wrong")
                setIsLoading(false)
            }
        )
    }

    const setMaxFileSize = (max_file_size) => {
        setIsLoading(true)
        let bites = max_file_size * 1000000
        setMaxFileSizeAPICall(
            bites,
            (response) => {
                const Mb = handleConvertBitesToMb(response.data.max_file_size)
                setMb(Mb);
                notifySuccess("Changed");
                setIsLoading(false);
            },
            (error) => {
                notifyError("Something wrong")
                console.log(error);
                setIsLoading(false)
            }

        )
    }
    useEffect(() => {
        handleGetMaxFileSize()
    }, [])

    return (
        <AalyticsOptionPageContainer>
            {/* <Title><h1>Configuration</h1></Title> */}
            <Group>
                <Line>
                    <p>  Maximum upload file size</p>
                </Line>
                <Line>
                    <InputGroup>
                        <CustomInput
                            value={mb}
                            onChange={(e) => setMb(e.currentTarget.value)}
                        />
                        <p>MB</p>
                    </InputGroup>
                    <PrimaryButton
                        onClick={() => setMaxFileSize(mb)}
                    >Save
                    </PrimaryButton>
                </Line>
            </Group>
        </AalyticsOptionPageContainer>
    )
}

export default ConfigurationOptionPage

const AalyticsOptionPageContainer = styled.div`
display:flex;
flex-direction:column;
gap:35px;
padding:35px 50px;
background:${colors.dashBoard.bg};
height:100vh;
position:relative;
`

const Title = styled.div`
h1{
font-size: 25px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
`
const InputGroup = styled.div`
display:flex;
border-radius:10px;
align-items:center;
gap:5px;
background:${colors.dashBoard.inputBg};
width:fit-content;
p{
    color:${colors.white};
    font-size: 16px;
font-weight: 600;
line-height: 22px;
}
padding:3px 10px;
`
const StatisticContainer = styled.div`
display:flex;
flex-direction:column;
gap:15px;
border-bottom: 1px solid rgba(54, 62, 81, 0.5);
padding:20px 30px;
&:last-child{
    border-bottom:none;
}
h1{
color:white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
}
h2{
    color:${colors.dashBoard.purple};
font-size: 30px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
`

const CustomInput = styled.input`
width:100%;
padding:8px 16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
border:none;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.dashBoard.elementColor};
&:focus{
    outline:none;
}
`
const Line = styled.div`
display:flex;
gap:10px;
align-items:center;
p{
    color:${colors.white};
    font-size:14px;
    font-weight:600;
}
`
const PrimaryButton = styled.button`
font-size: 16px;
font-weight: 600;
line-height: 22px;
padding:11px 26px;
background:${colors.dashBoard.purple};
color:${colors.white};
border:none;
border-radius:10px;
cursor:pointer;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:15px;
`