import {
    createContext,
    useContext,
    useCallback,
} from "react";
import { addNewUsersMessageAPI } from "../API/platformChat/newUsersMessages/addNewUsersMessage";
import { deleteNewUsersMessageAPI } from "../API/platformChat/newUsersMessages/deleteNewUsersMessage";
import { getNewUserMessagesAPI } from "../API/platformChat/newUsersMessages/getNewUserMessages";
import { sendMessageToOldUsersAPI } from "../API/platformChat/oldUsersMessages/sendMessageToOldUsers";
import { getOldUserMessagesAPI } from "../API/platformChat/oldUsersMessages/getOldUsersMessages";

export const MessageToUsersContext = createContext({
    add_new_user_message: () => { },
    delete_new_user_message: () => { },
    get_new_user_message: () => { },
    get_old_user_message: () => { },
    send_message_to_old_users: () => { },
});

const MessageToUsersContextProvider = ({ children }) => {

    const add_new_user_message = useCallback(
        async (data, callbackFunction, callbackError) => {
            try {
                addNewUsersMessageAPI(
                    data,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const delete_new_user_message = useCallback(
        async (messageId, callbackFunction, callbackError) => {
            try {
                deleteNewUsersMessageAPI(
                    messageId,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const get_new_user_message = useCallback(
        async (callbackFunction, callbackError) => {
            try {
                getNewUserMessagesAPI(
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const get_old_user_message = useCallback(
        async (data, callbackFunction, callbackError) => {
            try {
                getOldUserMessagesAPI(
                    data,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const send_message_to_old_users = useCallback(
        async (data, callbackFunction, callbackError) => {
            try {
                sendMessageToOldUsersAPI(
                    data,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );





    return (
        <MessageToUsersContext.Provider
            value={{
                add_new_user_message,
                delete_new_user_message,
                get_new_user_message,
                get_old_user_message,
                send_message_to_old_users
            }}
        >
            {children}
        </MessageToUsersContext.Provider>
    );
};

export const useMessageToUsersService = () => useContext(MessageToUsersContext);

export default MessageToUsersContextProvider;
