import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import UserElement from '../UsersComponents/UserElement'
import { ReactComponent as BackArrow } from '../../Assets/icons/backArrow.svg'
import { ReactComponent as ForwardArrow } from '../../Assets/icons/forwardArrow.svg'
import { getUsersAPI } from '../../API/users/getUsers'
import { searchUserAPI } from '../../API/users/searchUser'
import { deleteUserAPI } from '../../API/users/deleteUser'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'
import { setUserBlockAPI } from '../../API/users/blockUnblockUser'


const UserOptionPage = ({ setIsLoading }) => {

    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [searchUserTerm, setSearchUserTerm] = useState("")

    const handleGetUsers = () => {
        setIsLoading(true);
        if (searchUserAPI.length > 2) {
            setSearchUserTerm("");
        }
        getUsersAPI(
            page,
            function (response) {
                setUsers(response.data.users);
                setIsLoading(false);
            },
            function (error) {
                setIsLoading(false)
                console.log(error)
                notifyError("Something wrong...")
            }
        )
    }


    const handleSearchUser = () => {
        setPage(1);
        setIsLoading(true);
        searchUserAPI(
            searchUserTerm,
            function (response) {
                setIsLoading(false);
                setUsers(response.data.users);
            },
            function (error) {
                setIsLoading(false)
                notifyError("Something wrong...")
                console.log(error);
            }

        )
    }




    const handleRemoveUser = (userId) => {
        setIsLoading(true);
        deleteUserAPI(
            userId,
            function (response) {
                notifySuccess("User deleted")
                handleGetUsers();
            },

            function (error) {
                notifyError("Something wrong");
                setIsLoading(false);
                console.log(error);
            }
        )
    }

    //TODO-remove when change user action
    const handleBlockUser = (userId, blocked) => {
        setIsLoading(true)
        setUserBlockAPI(
            userId,
            blocked,
            function (response) {
                const newUser = response.data.user;;
                handleGetUsers();
                setIsLoading(false)
            },
            function (error) {
                console.log(error);
                notifyError("Something wrong")
                setIsLoading(false)
            }
        )
    }

    //TODO-remove when change user action
    const handleBlockWithConfirmation = (userId) => {
        handleBlockUser(userId, true)
    }

    useEffect(() => {
        if (searchUserTerm.length > 2) {
            let timer = setTimeout(() => {
                handleSearchUser()
            }, 300)
            return () => clearTimeout(timer)
        } else {
            let timer = setTimeout(() => {
                handleGetUsers()
            }, 300)
            return () => clearTimeout(timer)
        }

    }, [page, searchUserTerm])

    return (
        <UserOptionPageContainer>

            <SearchWrapper>
                <CustomInput
                    placeholder='Search user'
                    value={searchUserTerm}
                    onChange={(e) => setSearchUserTerm(e.currentTarget.value)}
                />
                <SearchBtn>
                    Search
                </SearchBtn>
            </SearchWrapper>

            <UsersListWrapper>
                {users.map((user) => (
                    <UserElement
                        key={user.id}
                        avatar={user.profile_picture}
                        username={user.name}
                        user={user}
                        handleRemove={handleRemoveUser}
                        handleBlockWithConfirmation={handleBlockWithConfirmation}//TODO-remove when change user action
                        handleBlockUser={handleBlockUser}//TODO-remove when change user action
                    />
                ))}
            </UsersListWrapper>
            <PaginationWrapper>
                <Pagination>
                    {page != 1 &&
                        <PaginationBtn onClick={() => setPage(page - 1)}>
                            <BackArrow />
                        </PaginationBtn>}

                    <PageNumber>
                        <p>{page}</p>
                    </PageNumber>
                    {page > 0 && users.length == 25 &&
                        <PaginationBtn onClick={() => setPage(page + 1)}>
                            <ForwardArrow />
                        </PaginationBtn>}

                </Pagination>

            </PaginationWrapper>
        </UserOptionPageContainer>


    )
}

export default UserOptionPage
const UserOptionPageContainer = styled.div`
display:flex;
flex-direction:column;
gap:35px;
padding:35px 50px;
background:${colors.dashBoard.bg};
height:100vh;
overflow:auto;
position:relative;
`
const SearchWrapper = styled.div`
display:flex;
align-items:center;
gap:25px;
max-width:960px;
min-width:600px;
`
const CustomInput = styled.input`
width:100%;
padding:8px 16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
border:none;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.dashBoard.elementColor};
&:focus{
    outline:none;
}
`

const SearchBtn = styled.button`
cursor:pointer;
padding:8px;
border-radius: 10px;
gap: 8px;
background:${colors.dashBoard.purple};
color:${colors.dashBoard.elementColor};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border:none;
width:131px;
`
const UsersListWrapper = styled.div`
display:flex;
flex-direction:column;
background:${colors.dashBoard.elementBg};
border-radius:10px;
padding:0 20px;
position:relative;
background-opacity:0.7;
`
const PaginationWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;

`
const PaginationBtn = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:${colors.dashBoard.purple};
min-width:40px;
min-height:40px;
cursor:pointer;

svg{
   color:${colors.white};
}
`
const Pagination = styled.div`
border-radius:4px;
overflow:hidden;
display:flex;
align-items:center;
justify-content:center;
background:${colors.dashBoard.purple};
`
const PageNumber = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:${colors.dashBoard.purple};
min-width:45px;
min-height:40px;
border-left:1px solid rgba(250, 250, 250, 0.2);
border-right:1px solid rgba(250, 250, 250, 0.2);
margin:5px 0;
p{
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color:${colors.dashBoard.elementColor};
}
`