import toast from "react-hot-toast";
import { colors } from "../Assets/styles/colors";


export const notifySuccess = (message) => {
    toast.success(message, {
        style: {
            border: `1px solid ${colors.dashBoard.purple}`,
            color: colors.dashBoard.elementColor,
            background: colors.newChatModalInputBg
        },

    })
}
export const notifyError = (message) => {
    toast.error(message, {
        style: {
            border: `1px solid ${colors.dashBoard.errorColor}`,
            color: colors.dashBoard.elementColor,
            background: colors.newChatModalInputBg
        },

    })
}



