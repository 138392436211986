import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
// import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";
import { ReactComponent as NoAvatarIcon } from '../../Assets/icons/noAvatarIcon.svg'
import TimeAgo from "timeago-react";

const ChatListElement = ({ handleSelectConversation, data, selected, userId }) => {

  const findTranslatedTextForUser = (translations) => {

    let translated = translations?.find((item) => item.user_id === userId)
    return translated
  }

  let profilePicture = data?.other_participants[0]?.profile_picture
  let name = data?.other_participants[0]?.name

  if (data.group != null) {
    profilePicture = data.group?.image
    name = data.group.name
  }

  const handleLastMessage = (last_message) => {

    if (!last_message) {
      return
    }

    if (last_message.image) {
      return "Image"
    }

    else {
      let translatedForCurrentUser = findTranslatedTextForUser(last_message.message_translations)

      if (!data.group) {
        return translatedForCurrentUser?.translation
      }

      else {
        if (last_message.user) {
          return (`~ ${last_message.user?.name}: ` + translatedForCurrentUser?.translation)
        }

      }
    }
  }


  const displayNewOrTimeAgo = (last_message) => {
    if (!last_message) {
      return
    }
    // if (last_message && last_message.user_id != userId && last_message.read == false) {
    //   return <h2>New</h2>
    // } else {
    if (last_message && last_message.user_id) {
      return <TimeAgo
        datetime={data.last_message.created_at}
        style={{ color: "white", fontSize: "12px" }}
      />
    }
    // }
  }


  return (
    <ChatListElementContainer onClick={() => handleSelectConversation(data)} selected={selected}>
      <UserAvatar>
        {profilePicture ?
          <img src={profilePicture} alt="avatar" />
          :
          < NoAvatarIcon />
        }
      </UserAvatar>

      <Text>
        <PhoneNumber>
          <h1>{name}</h1>
          {displayNewOrTimeAgo(data.last_message)}
        </PhoneNumber>
        <Message>
          <p>{handleLastMessage(data.last_message)}</p>
        </Message>
      </Text>
    </ChatListElementContainer>
  );
};

export default ChatListElement;
const ChatListElementContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: ${(props) => props.selected ? colors.chatElementBgSelected : colors.chatElementBg};
  border-radius: 16px;
  gap: 16px;
  border:${(props) => props.selected ? "1px solid #5852D633" : ""};
margin:${(props) => props.selected ? "-1px" : ""};

`;
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 45px;
  max-width: 45px;
  object-fit: contain;
  position: relative;
  min-width: 45px;
  min-height:45px;
  overflow:hidden;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img{
    object-fit:cover;
 position: relative;
       height:43px;
    width:43px;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
overflow:hidden;
width:100%;
`;
const PhoneNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {

    color: ${colors.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${colors.purple};
  }
`;
const Message = styled.div`
width:100%;
  p {
    width:100%;
    display:-webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${colors.chatElementColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
