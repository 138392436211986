export const colors = {
    background: "#222531",
    purple: "rgba(112, 105, 253, 1)",
    newChatModalInputBg: "#30323A",
    userMenuHeaderBg: "#21242E",
    white: "rgba(255,255,255,1)",
    chatElementBg: "#1C1F26",
    chatElementBgSelected: "#5852D633",
    chatElementColor: "#EBEBF5CC",
    receivedMessageBg: "#333546",
    chatBg: "#171719",
    chatsListBg: "rgba(35, 37, 49, 1)",
    errorColor: "#EE5050",
    newChatModalBg: "rgba(26, 29, 36, 1)",
    optionsGroupTitle: "#757575",
    messageNotRead: "#5B5B5D",
    newButtonOptionsBg: "rgba(48, 50, 58, 1)",
    purple20: "rgba(112, 105, 253, 0.2)",

    dashBoard: {
        headerBg: "rgba(42, 45, 55, 1)",
        bg: "rgba(23, 23, 25, 1)",
        inputBg: "rgba(48, 50, 58, 1)",
        elementBg: "rgba(35, 37, 49, 1)",
        elementColor: "rgba(250, 250, 250, 1)",
        errorColor: "rgba(238, 80, 80, 1)",
        purple: "rgba(112, 105, 253, 1)",
    },

    conversationView: {
        userMenuHeaderBg: "#21242E",
        chatsListBg: "rgba(35, 37, 49, 1)",
        purple: "#7069FD",
        errorColor: "rgba(238, 80, 80, 1)",
    },

    navBar: {
        bg: "rgba(26, 29, 36, 1)",
        color: "rgba(255, 255, 255, 1)",
        selctedColor: " rgba(112, 105, 253, 1)"
    }

}