import React from 'react'
import styled from 'styled-components'
import SearchMenu from './SearchMenu'
import ChatListElement from './ChatListElement'


const ConversationsList = ({
    searchInput,
    setSearchInput,
    conversations,
    selectedConversation,
    handleSelectConversation,
    userId,
}) => {

    const filterConversations = (conversations, filterKey) => {
        if (filterKey === "") {
            return conversations
        } else {
            let filtered = [];

            conversations.map((conversation) => {
                conversation.other_participants.map((element) => {
                    if (element.phone_number.includes(filterKey) ||
                        element.name.toLowerCase().includes(filterKey.toLowerCase())
                    ) {
                        filtered.push(conversation)
                    }
                })
                if (conversation.group) {
                    if (conversation.group.name.toLowerCase().includes(filterKey.toLowerCase())) {
                        filtered.push(conversation)
                    }
                }

            })
            return [...new Set(filtered)];
        }
    }

    return (
        <ChatsList>
            <SearchMenu
                value={searchInput}
                setvalue={setSearchInput}
            />

            {filterConversations(conversations, searchInput)?.map((conversation, index) => (
                <ChatListElement
                    key={index}
                    data={conversation}
                    selected={selectedConversation?.id === conversation.id}
                    handleSelectConversation={handleSelectConversation}
                    userId={userId}
                />
            ))}

        </ChatsList>
    )
}

export default ConversationsList

const ChatsList = styled.div`
display:flex;
flex-direction:column;
gap:20px;
padding:0 20px 10px 20px;
overflow-Y:auto;
overflow-X:hidden;
`