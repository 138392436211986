
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import LLMModelsOption from '../LLMComponents/LLMModelsOption'
import LLMLanguagesOption from '../LLMComponents/LLMLanguagesOption'
import { getAllModelsAPICall, getTranslationPairAPICall } from '../../API/settings/LLMService'
import { useLlmService } from '../../Context/LLMContext'

const LlmOptionPage = ({ setIsLoading }) => {
    const MODELS = "models"
    const LANGUAGES = "languages"


    const [selectedOption, setSelectedOption] = useState(MODELS);
    const { models, getAllModels, getTranslationPairs, getPrompyKeywords } = useLlmService();

    const handleSelectOption = (value) => {
        setSelectedOption(value)
    }

    var content;

    switch (selectedOption) {

        case MODELS:
            content = <LLMModelsOption setIsLoading={setIsLoading} />
            break;

        case LANGUAGES:
            content = <LLMLanguagesOption setIsLoading={setIsLoading} />
            break;

        default:
            break;
    }



    useEffect(() => {
        setIsLoading(true)
        getAllModels((response) => { setIsLoading(false) }, (error) => { setIsLoading(false) })
        getPrompyKeywords((response) => { }, (error) => { })
        getTranslationPairs((response) => { }, (error) => { })
    }, [])


    return (
        <LlmPageContainer>
            <OptionSelector>
                <OptionButton
                    onClick={(e) => handleSelectOption(e.currentTarget.value)}
                    value={MODELS}
                    $selected={selectedOption === MODELS}
                >
                    Models
                </OptionButton>
                <OptionButton
                    onClick={(e) => handleSelectOption(e.currentTarget.value)}
                    value={LANGUAGES}
                    $selected={selectedOption === LANGUAGES}
                >
                    Languages
                </OptionButton>
            </OptionSelector>

            <SelectedOptionWrapper>
                {content}
            </SelectedOptionWrapper>

        </LlmPageContainer>
    )
}

export default LlmOptionPage
const LlmPageContainer = styled.div`
display:flex;
flex-direction:column;
gap:50px;
padding:54px;
background:${colors.dashBoard.bg};
height:100vh;
position:relative;
`

const OptionSelector = styled.div`
display:flex;
align-items:center;
padding:4px;
border-radius:8px;
background:${colors.dashBoard.headerBg};
width:fit-content;
`

const OptionButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: center;
color:${colors.white};
padding:12px;
min-width:170px;
border:none;
background:${props => props.$selected ? colors.purple : "transparent"};
opacity:${props => props.$selected ? 1 : 0.4};
cursor:pointer;
border-radius:8px;
`
const SelectedOptionWrapper = styled.div`
height:100%;

`