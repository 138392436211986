import React, { useEffect } from 'react'
import { ReactComponent as CloseIcon } from '../../Assets/icons/closeIcon.svg'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { MediaPlayer, MediaProvider } from '@vidstack/react'
import { DefaultVideoLayout, defaultLayoutIcons } from '@vidstack/react/player/layouts/default'
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import FileAttachmentItem from './FileAttachmentItem'


const MediaViewModal = ({ handleCloseModal, imagePreview, videoPreview, filePreview }) => {

    var imageHeight = 400;
    var imageWidth = 350;

    const calculateAspect = (dimension) => {
        const ratio = Math.min(1400 / imagePreview.width, 1600 / imagePreview.height);
        return (dimension * ratio)
    }

    if (imagePreview) {
        imageWidth = calculateAspect(imagePreview?.width);
        imageHeight = calculateAspect(imagePreview?.height);
    }


    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleCloseModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
    return (
        <FullScreenContainer>
            <Card>
                <CloseBtn >
                    <CloseIcon onClick={() => handleCloseModal()} />
                </CloseBtn>
                <Content>
                    {imagePreview && <img src={imagePreview.url} alt='preview' />}
                    {videoPreview &&
                        <VideoPreview>
                            <MediaPlayer
                                load='visible'
                                posterLoad='visible'
                                controls
                                playsInline
                                src={videoPreview.video_url}
                            >
                                <MediaProvider />
                                <DefaultVideoLayout thumbnails={""} icons={defaultLayoutIcons} />
                            </MediaPlayer>
                        </VideoPreview>}
                    {filePreview && <FileAttachmentItem file={filePreview} />}


                </Content>
            </Card>
        </FullScreenContainer>

    )
}

export default MediaViewModal
const FullScreenContainer = styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100%;
background:rgba(0,0,0,0.3);
z-index:5;
display:flex;
justify-content:center;
align-items:center;
padding:5vh 10vw;
`

const Card = styled.div`
display:flex;
flex-direction:column;
padding:55px;
border-radius:12px;
gap:50px;
background:${colors.newChatModalBg};
position:relative;
max-height:90vh;
max-width:90vw;
overflow:hidden;
min-width:300px;
min-height:200px;
`

const CloseBtn = styled.button`
display: flex;
align - items: center;
justify - content: center;
border: none;
background: none;
cursor: pointer;
position: absolute;
top: 20px;
right: 20px;
z-index: 2;
font-size: 20px;
font-weight: 600;
color:${colors.white};
width: fit-content;
`

const Content = styled.div`
height: 100%;
display: flex;
position: relative;
align-items: center;
justify-content: center;
overflow:hidden;
img{
    object-fit:contain;
    overflow: hidden;
    cursor: pointer;
}
`
const VideoPreview = styled.div`
display:flex;
height:100%;
width:100%;
min-width:500px;
max-height:600px
background:${colors.chatBg};
justify-content:center;
align-items:center;
position:relative;
overflow:hidden;
`