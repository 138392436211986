import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import UserOptionPage from './UserOptionPage'
import { ANALYTICS_OPTION, CONFIGURATION_OPTION, LLM_OPTION, LOGS_OPTION, MESSAGES_OPTION, PROFILE_OPTION, USER_OPTION } from '../../Constants/OptionsConstants'
import LogsOptionPage from './LogsOptionPage'
import AalyticsOptionPage from './AalyticsOptionPage'
import MessagesOptionPage from './MessagesOptionPage'
import ConfigurationOptionPage from './ConfigurationOptionPage'
import LlmOptionPage from './LlmOptionPage'
import ProfileOptionPage from '../ProfileComponents/ProfileOptionPage'

const DashBoardContent = ({ option, setIsLoading }) => {
    var content;
    switch (option) {
        case USER_OPTION:
            content = <UserOptionPage setIsLoading={setIsLoading} />
            break;
        case LOGS_OPTION:
            content = <LogsOptionPage setIsLoading={setIsLoading} />
            break;
        case ANALYTICS_OPTION:
            content = <AalyticsOptionPage setIsLoading={setIsLoading} />
            break;
        case MESSAGES_OPTION:
            content = <MessagesOptionPage setIsLoading={setIsLoading} />
            break;
        case CONFIGURATION_OPTION:
            content = <ConfigurationOptionPage setIsLoading={setIsLoading} />
            break;
        case LLM_OPTION:
            content = <LlmOptionPage setIsLoading={setIsLoading} />
            break;
        case PROFILE_OPTION:
            content = <ProfileOptionPage setIsLoading={setIsLoading} />
            break;

        default:
            break;
    }
    return (
        <Container>

            <SelectedOptionWrapper>
                {content}
            </SelectedOptionWrapper>

        </Container>
    )
}

export default DashBoardContent

const Container = styled.div`
display:flex;
flex-direction:column;
width:100%;
height:100vh;
`
const Header = styled.div`
min-height:30px;
background:${colors.dashBoard.bg};
width:100%;
`
const SelectedOptionWrapper = styled.div`
height:100%;

`