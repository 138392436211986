import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import { signInAdmin } from "../API/authentication/loginAdmin";
import LocalStorage from "../Helpers/LocalStorage";
import { logoutAdminAPI } from "../API/authentication/logoutAdmin";
import { getCurrentUserAPI } from "../API/authentication/currentUser";
import { getTranslationsLanguagesAPI } from "../API/users/getTranslationsLanguages";
import { getOtpQRAPICall, getOtpStatusAPICall, set2FAEnabledAPICall } from "../API/authentication/getOtpStatus";

export const AuthContext = createContext({
    user: [],
    sign_in_admin: () => { },
    logout_admin: () => { },
    get_current_user: () => { },
    translationLanguages: [],
    getTranslationsLanguages: () => { },
    getOtpStatus: () => { },
    getOtQrCode: () => { },
    set2FAEnabled: () => { },
    handleLoginUser: () => { },

});

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [translationLanguages, setTranslationLanguages] = useState([])


    const sign_in_admin = useCallback(
        async (email, password, otpCode, callbackFunction, callbackError) => {
            try {
                signInAdmin(
                    email,
                    password,
                    otpCode,
                    function (response) {
                        handleLoginUser(response.data.admin)
                        LocalStorage.setAuthenthicationToken(response.data.access_token);
                        LocalStorage.setRefreshToken(response.data.refresh_token);
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const logout_admin = useCallback(
        async (email, password, callbackFunction, callbackError) => {
            try {
                logoutAdminAPI(
                    email,
                    password,
                    function (response) {
                        handleLogoutUser();
                        LocalStorage.setAuthenthicationToken(null);
                        LocalStorage.setRefreshToken(null);
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const get_current_user = useCallback(
        async (callbackFunction, callbackError) => {
            try {
                getCurrentUserAPI(
                    function (response) {
                        handleLoginUser(response.data.admin)
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );
    const getOtpStatus = useCallback(
        async (email, callbackFunction, callbackError) => {
            try {
                getOtpStatusAPICall(
                    email,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const getOtQrCode = useCallback(
        async (callbackFunction, callbackError) => {
            try {
                getOtpQRAPICall(
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const set2FAEnabled = useCallback(
        async (enabled, callbackFunction, callbackError) => {
            try {
                set2FAEnabledAPICall(
                    enabled,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const getTranslationsLanguages = useCallback(async (onSucces, errorCallback) => {
        try {
            await getTranslationsLanguagesAPI(
                function (response) {
                    onSucces(response);
                    setTranslationLanguages(response.data.languages)
                },
                function (error) {
                    errorCallback(error);
                }
            );
        } catch (err) {
            errorCallback();
        }
    });

    const handleLoginUser = (user) => {
        setUser(user);
    };

    const handleLogoutUser = () => {
        setUser(null);
    };


    return (
        <AuthContext.Provider
            value={{
                user,
                sign_in_admin,
                logout_admin,
                get_current_user,
                translationLanguages,
                getTranslationsLanguages,
                getOtpStatus,
                getOtQrCode,
                set2FAEnabled,
                handleLoginUser

            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthService = () => useContext(AuthContext);

export default AuthContextProvider;
