import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as SendMessageIcon } from '../../Assets/icons/sendMessageButton.svg'
import FileAttachmentItem from './FileAttachmentItem';


const SendFile = ({
    fileInput,
    setFileInput,
    handleSendFileButton,
    fileInputRef,
    messageInputRef,
    handleInputChange,
    inputValue
}) => {

    const [src, setSrc] = useState({
        src: null,
        type: null
    });

    const handleClose = () => {
        fileInputRef.current.value = null
        setFileInput(null);
    }


    const handleSend = (file, message) => {
        handleSendFileButton(file, message);
        handleClose();
    }


    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSend(fileInput, inputValue)
        }
    }


    useEffect(() => {

    }, [fileInput])


    return (
        <Container>
            <Top>
                <CloseBtn onClick={() => handleClose()}>
                    <CloseIcon />
                </CloseBtn>
                <Title>
                    <h1>{"Send file"}</h1>
                </Title>
                <div></div>

            </Top>

            <FilePreview>
                <FileAttachmentItem
                    file={fileInput}
                />
            </FilePreview>

            <MediaSendDetails>
                <SendTo>
                    <h3>{"Send to "}</h3>
                    <h2>Users</h2>
                </SendTo>

            </MediaSendDetails>
            <MessageInputWrapper
                onKeyDown={(e) => handleKeyDown(e)}
            >

                <MessageInput

                    ref={messageInputRef}
                    autoFocus
                    placeholder={"Add caption"}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e)}
                />
                <SendMessageButton onClick={() => handleSend(fileInput, inputValue)}>
                    <SendMessageIcon />
                </SendMessageButton>

            </MessageInputWrapper>
        </Container>
    )
}

export default SendFile

const Container = styled.div`
display:flex;
flex-direction:column;
position:absolute;
top:0;
bottom:0;
height:100%;
background:${colors.userMenuHeaderBg};
width:100%;
overflow:hidden;
z-index:10;
`
const Top = styled.div`
display:flex;
justify-content:space-between;
padding:16px;
min-height:74px;
height:74px;
position:relative;
`
const MediaSendDetails = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
padding:16px;
min-height:56px;
height:56px;
position:relative;
border-bottom:1px solid ${colors.darkPurple};
`
const ImagePreview = styled.div`
display:flex;
height:100%;
width:100%;
max-height:calc(100% - 204px);
background:${colors.chatBg};
justify-content:center;
align-items:center;
position:relative;
img{
    position:relative;
    object-fit:contain;
    widht:100%;
    height:100%;
}
`
const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
`
const Title = styled.div`
align-items:center;
display:flex;
h1{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
}
`

const SendMessageButton = styled.button`

width:42px;
height:42px;
border-radius:100%;
display:flex;
jusitfy-content:center;
align-items:center;
cursor:pointer;
border:none;
background:none;
&:disabled{
opacity:0.5;
cursor:inherit;
}
`

const SendTo = styled.div`
display:flex;
align-items:center;
gap:10px;
h2{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color:${colors.white};
}
h3{
    font-size:14px;
    font-weight:500;
    line-height: 20px;
    color:${colors.white};
    opacity:0.5;
}
`
const MessageInput = styled.input`
width:100%;
border-radius:100px;
padding:10px 16px;
border:none;
background:#D9D9D90D;
color:${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
&:focus{
outline:none;
}
`
const MessageInputWrapper = styled.div`
display:flex;
background:${colors.userMenuHeaderBg};
padding:16px;
min-height:74px;
height:74px;
min-height:74px;
max-height:74px;
height:100%;
gap:12px;

`

const FilePreview = styled.div`
display:flex;
flex-direction:column;
height:100%;
width:100%;
max-height:calc(100% - 204px);
background:${colors.chatBg};
justify-content:center;
align-items:center;
position:relative;
overflow:hidden;
p{
    color:${colors.white};
}
`