
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'
import { getMaxFileSizeAPICall, setMaxFileSizeAPICall } from '../../API/settings/maxFileSize'
import { FormControlLabel } from '@mui/material'
import { IOSSwitch } from './CustomSwitch'
import { useAuthService } from '../../Context/AuthContext'

const ProfileOptionPage = ({ setIsLoading }) => {

    const { user, getOtQrCode, set2FAEnabled, handleLoginUser } = useAuthService();

    const [FAEnabled, setFaEnabled] = useState();
    const [qrCodeSvg, setQrCodeSvg] = useState(null)


    const handleSet2FAEnabled = (enabled) => {
        set2FAEnabled(
            enabled,
            (response) => {
                let admin = response.data.admin
                handleLoginUser(admin)

            },
            (error) => {
                console.log(error)
            },

        )
    }

    useEffect(() => {
        setFaEnabled(user.otp_required_for_login)
    }, [user])

    useEffect(() => {
        getOtQrCode(
            (response) => {
                const svg = response.data
                setQrCodeSvg(svg)
            },
            (error) => {
                console.log(error)
            },
        )
    }, [])

    return (
        <AalyticsOptionPageContainer>
            <TitleWrapper>
                <Title><h1>Profile</h1></Title>
            </TitleWrapper>
            <Group>
                <Line>
                    <p> 2FA Enabled</p>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={<IOSSwitch
                            checked={FAEnabled}
                            onChange={(e) => handleSet2FAEnabled(e.target.checked)}
                        />}
                    />
                </Line>
                {FAEnabled &&
                    <>
                        <Line style={{ marginTop: "50px" }}>
                            <p> Scan QR Code for 2FA in your auth app</p>
                        </Line>
                        <SvgLine>
                            <svg dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />
                        </SvgLine>

                    </>
                }

            </Group>
        </AalyticsOptionPageContainer>
    )
}

export default ProfileOptionPage

const AalyticsOptionPageContainer = styled.div`
display:flex;
flex-direction:column;
gap:35px;
padding:35px 50px;
background:${colors.dashBoard.bg};
height:100vh;
position:relative;
`

const Title = styled.div`
h1{
font-size: 25px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
`
const InputGroup = styled.div`
display:flex;
border-radius:10px;
align-items:center;
gap:5px;
background:${colors.dashBoard.inputBg};
width:fit-content;
p{
    color:${colors.white};
    font-size: 16px;
font-weight: 600;
line-height: 22px;
}
padding:3px 10px;
`
const StatisticContainer = styled.div`
display:flex;
flex-direction:column;
gap:15px;
border-bottom: 1px solid rgba(54, 62, 81, 0.5);
padding:20px 30px;
&:last-child{
    border-bottom:none;
}
h1{
color:white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
}
h2{
    color:${colors.dashBoard.purple};
font-size: 30px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
`

const CustomInput = styled.input`
width:100%;
padding:8px 16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
border:none;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.dashBoard.elementColor};
&:focus{
    outline:none;
}
`
const Line = styled.div`
display:flex;
gap:10px;
align-items:center;

p{
    color:${colors.white};
    font-size:14px;
    font-weight:600;
}
`
const PrimaryButton = styled.button`
font-size: 16px;
font-weight: 600;
line-height: 22px;
padding:11px 26px;
background:${colors.dashBoard.purple};
color:${colors.white};
border:none;
border-radius:10px;
cursor:pointer;
`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:15px;
`
const SvgLine = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:white;
width:200px;
height:200px;
svg{
    fill:white;
}
`
const TitleWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`