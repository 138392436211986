import { DELETE, GET, POST, PUT } from "../API";

let getAllModelsAPICallsource;
let getPromptKeywordsAPICallsource;
let getTranslationPairAPICallsource;

export const getAllModelsAPICall = (
    onSuccess,
    onError
) => {
    getAllModelsAPICallsource = GET(`/api/v1/administration/models/all`, onSuccess, onError, true, getAllModelsAPICallsource)
}

export const getPromptKeywordsAPICall = (
    onSuccess,
    onError
) => {
    getPromptKeywordsAPICallsource = GET(`/api/v1/administration/models/prompt_keywords`, onSuccess, onError, true, getPromptKeywordsAPICallsource)
}

export const setDefaultModelAPICall = (
    id,
    onSuccess,
    onError
) => {
    const data = {
        id: id
    }
    POST(`/api/v1/administration/models/set_default`, data, onSuccess, onError,)
}

export const setModelPromptAPICall = (
    id,
    text,
    onSuccess,
    onError
) => {

    const data = {
        id: id,
        text: text,
    }

    POST(`/api/v1/administration/models/set_prompt`, data, onSuccess, onError)
}


export const addTranslationPairAPICall = (
    from,
    to,
    modelId,
    onSuccess,
    onError
) => {

    const data = {
        from: from,
        to: to,
        model_id: modelId
    }

    POST(`/api/v1/administration/models/add_translation_pair`, data, onSuccess, onError,)
}

export const deleteTranslationPairAPICall = (
    id,
    onSuccess,
    onError
) => {

    DELETE(`/api/v1/administration/models/delete_translation_pair?id=${id}`, onSuccess, onError)
}

export const getTranslationPairAPICall = (
    onSuccess,
    onError
) => {

    getTranslationPairAPICallsource = GET(`/api/v1/administration/models/translation_pairs`, onSuccess, onError, true, getTranslationPairAPICallsource)
}

export const updateTranslationPairAPICall = (
    from,
    to,
    modelId,
    id,
    onSuccess,
    onError
) => {

    const data = {
        from: from,
        to: to,
        model_id: modelId,
        id: id
    }

    PUT(`/api/v1/administration/models/update_translation_pair`, data, onSuccess, onError,)
}



export const setParamsAPICall = (
    id, temperature, top_k, top_p, response_length,
    onSuccess,
    onError
) => {

    const data = {
        id: id,
        temperature: temperature,
        top_k: top_k,
        top_p: top_p,
        response_length: response_length
    }

    POST(`/api/v1/administration/models/set_params`, data, onSuccess, onError,)
}