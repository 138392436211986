import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";

import { colors } from "../../Assets/styles/colors";

import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as AvatarPencil } from "../../Assets/icons/avatarPencil.svg";

import ContactElement from "./ContactElement";

const GroupDetailsView = ({
  groupData,
  user,
}) => {


  const initialValues = {
    groupName: groupData.group.name,
  };


  const profileAvatarRef = useRef();
  const [previewAvatar, setPreviewAvatar] = useState()
  const [inputValues, setInputValues] = useState(initialValues);
  const [selectedContacts, setSelectedContacs] = useState([]);




  const calculateGroupMembers = () => {
    let found = groupData.other_participants.find((item) => item.id === user.id)
    if (!found) {
      return groupData.other_participants.length + 1
    } else {
      return groupData.other_participants.length
    }
  }

  const displayCurrentContactElement = () => {
    let found = groupData.other_participants.find((item) => item.id === user.id)
    if (!found) {
      return (
        <ContactElement
          item={user}
          // handleRemove={handleRemoveMember}
          withConfirmationDelete={true}
          variant={"me"}
        />
      )
    }
  }




  useEffect(() => {
    setSelectedContacs(groupData.other_participants)
    setPreviewAvatar(groupData.group.image)
  }, [groupData])




  return (
    <AddNewGroupContainer>
      <Group>
        <TitleWrapper>
          <h1>Group Details</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ProfileDetails>
            <UserWrapper>
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg "
                ref={profileAvatarRef}

              />
              <UserAvatarWrapper>
                <UserAvatar >
                  {previewAvatar ? <img src={previewAvatar} alt='avatar' /> : <AvatarUnset />}
                </UserAvatar>
                <PencilWrapper>
                  <AvatarPencil />
                </PencilWrapper>
              </UserAvatarWrapper>
              <CustomNameInput
                value={inputValues.groupName}
                placeholder="Enter group name"
                name="groupName"
                readOnly
              ></CustomNameInput>
            </UserWrapper>
          </ProfileDetails>
        </OptionWrapper>
      </Group>
      <Separator />

      {
        selectedContacts.length > 0 &&
        <Group>
          <TitleWrapper>
            <h1>{calculateGroupMembers()} Members</h1>
          </TitleWrapper>
          <MebersList>
            {selectedContacts.map((contact, index) => (
              <ContactElement
                key={index}
                item={contact}
                withConfirmationDelete={true}
              />
            ))}
            {displayCurrentContactElement()}
          </MebersList>
        </Group>
      }

    </AddNewGroupContainer>
  )

};

export default GroupDetailsView;

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-width: 42px;
  min-height: 42px;
  position: relative;
  overflow: hidden;
  object-fit: contain;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit:cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #363e5180;
  padding: 8px 0;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.white};
  }
`;

const ChatOption = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #363e5180;
  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color: #ffffff99;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  h2 {
    text-transform: capitalize;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;

const CustomInput = styled.input`
  background: none;
  border: none;

  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  &:focus-visible {
    outline: none;
  }
`;

const CustomNameInput = styled(CustomInput)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`;

const Separator = styled.div`
  background: rgba(54, 62, 81, 0.5);
  height: 1px;
  width: 100%;
`;

const MebersList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
  padding: 10px 0 10px 16px;
  gap: 8px;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  &:disabled{
opacity:0.5;
  }
`;

const UserAvatarWrapper = styled.div`
  position: relative;
`;

const PencilWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
`;

const ContactNotFound = styled.div`
display:flex;
justify-content:center;
p{
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: center;
color:${colors.white};
opacity:0.7;
padding-bottom:30px;
}
`