import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'

const OutlineButton = ({ icon, text, ...props }) => {
    return (
        <CustomButton {...props}>
            {icon}
            {text}
        </CustomButton>
    )
}

export default OutlineButton

const CustomButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
width:100%;
border:none;
border-radius:10px;
border:1px solid ${colors.purple};
padding:7px;
cursor:pointer;
background:none;
color:${colors.purple};
font-size: 16px;
font-weight: 600;
line-height: 24px;
`