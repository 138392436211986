import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../Assets/styles/colors'
import { useAuthService } from '../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { notifySuccess } from '../Helpers/Notifications'
import SpinnerComponent from '../Components/Generic Components/SpinnerComponent'
import LocalStorage from '../Helpers/LocalStorage'

const LoginPage = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("");
    const [otpCode, setOtpCode] = useState("");

    const { sign_in_admin, user, get_current_user, getOtpStatus } = useAuthService();
    const [isLoading, setIsLoading] = useState(false);
    const [otpStatus, setOtpStatus] = useState(null)
    const navigate = useNavigate()

    const handleSingIn = () => {
        setIsLoading(true)
        sign_in_admin(
            email,
            password,
            otpCode,
            function (response) {
                setIsLoading(false)
            },
            function (error) {
                setIsLoading(false)
                console.log(error)
            },
        );

    }

    useEffect(() => {
        if (user) {
            navigate("/")
        } else {
            if (LocalStorage.getAuthenticationToken() !== "null") {
                get_current_user(
                    function (response) { },
                    function (error) { }
                )
            }
        }
    }, []);

    const handleGetOtpStatus = (email) => {
        getOtpStatus(
            email,
            (response) => {
                console.log(response.data)
                setOtpStatus(response.data.otp_enabled)
            },
            (error) => {
                console.log(error)
            },

        )
    }

    const handleContinue = () => {
        handleGetOtpStatus(email);
    }



    const displayCredentials = () => {
        let display = <></>
        if (otpStatus === true) {
            display =
                <>
                    <Group>
                        <label>Password</label>
                        <CustomInput
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                    </Group>
                    <Group>
                        <label>OTP Code</label>
                        <CustomInput
                            type='text'
                            value={otpCode}
                            onChange={(e) => setOtpCode(e.currentTarget.value)}
                        />
                    </Group>

                </>
        }
        if (otpStatus === false) {
            display =
                <Group>
                    <label>Password</label>
                    <CustomInput
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                </Group>
        }
        return display
    }

    const displayButton = () => {
        let display =
            <Group>
                <LoginBtn onClick={(e) => handleContinue()}>
                    Continue
                </LoginBtn>


            </Group>

        if (otpStatus != null) {
            display =
                <Group>
                    <LoginBtn onClick={(e) => handleSingIn()}>
                        Login
                    </LoginBtn>


                </Group>

        }
        return display
    }

    return (
        <FullScreenContainer>
            <SpinnerComponent isLoading={isLoading} >
                <Card>
                    <Group>
                        <label>Email</label>
                        <CustomInput
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                    </Group>

                    {displayCredentials()}
                    {displayButton()}
                    {/* <Group>
                        <LoginBtn onClick={(e) => handleSingIn()}>
                            Login
                        </LoginBtn>
                    </Group> */}

                </Card>
            </SpinnerComponent>
        </FullScreenContainer>

    )
}

export default LoginPage
const FullScreenContainer = styled.div`
position:fixed;
top:0;
bottom:0;
left:0;
right:0;
background:${colors.dashBoard.bg};
height:100%;
display:flex;
align-items:center;
justify-content:center;
padding:10vh 10vw;
`
const Card = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
background:${colors.navBar.bg};
padding:50px;
border-radius:10px;
gap:25px;
`

const Group = styled.div`
display:flex;
flex-direction:column;
gap:10px;
min-width:350px;
label{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;   
    color:${colors.dashBoard.elementColor}; 
}
`
const CustomInput = styled.input`
width:100%;
padding:8px 16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
border:none;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.dashBoard.elementColor};
&:focus{
    outline:none;
}
`
const LoginBtn = styled.button`
cursor:pointer;
font-size: 16px;
font-weight: 600;
line-height: 24px;
border:none;
background:${colors.purple};
display:flex;
align-items:center;
justify-content:center;
padding:10px 0;
color:${colors.dashBoard.elementColor};
border-radius:10px;
`