import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";

import Message from "./Message";
import MessageReceived from "./MessageReceived";

import moment from "moment";
import { useAuthService } from "../../Context/AuthContext";
import cloneDeep from "clone-deep";

import DateSeparator from "./DateSeparator";

const ConversationMessagesWrapper = ({
    messages,
    handleSendMessage,
    inputValue,
    handleInputChange,
    handleOnScroll,
    shouldScrollBottom,
    shouldScroolToCurrentMessage,
    handleSendImage,
    handleReactToMessage,
    handleShowFullScreenImg,
    handleDeleteMessage,
    displayRightClickMenu,
    inputFileRef,
    scrollHeight,
    selectedConversation,
    user,
}) => {
    // const { user } = useAuthService();
    const bottomRef = useRef();
    const messageListRef = useRef();

    const compareDates = (dateA, dateB) => {
        let a = moment(dateA);
        let b = moment(dateB);
        return a.isBefore(b, "D");
    };

    const findTranslatedTextForUser = (translations) => {
        let translated = translations?.find((item) => item.user_id == user.id);
        return translated;
    };

    const createDateSeparators = (messages) => {
        if (!messages) {
            return;
        }
        let copy = cloneDeep(messages);
        for (let index = 1; index < copy.length; index++) {
            let prev = copy[index - 1];
            let current = copy[index];

            if (compareDates(prev.device_sent_date, current.device_sent_date)) {
                copy.splice(index, 0, {
                    type: "date-separator",
                    date: prev.device_sent_date,
                });
            }
        }

        copy.splice(0, 0, {
            type: "date-separator",
            date: copy[0]?.device_sent_date,
        });

        return copy;
    };

    const scrollToCurrentMessage = () => {
        let prev = scrollHeight;
        let element = document.getElementById("messagesList");
        const newHeight = element.scrollHeight;
        const scrollTo = newHeight - prev;
        element.scrollTo("top", scrollTo);
    };

    useEffect(() => {
        let element = document.getElementById("messagesList");
        if (
            shouldScrollBottom.current === false &&
            shouldScroolToCurrentMessage.current === true
        ) {
            scrollToCurrentMessage();
            return;
        }
        if (shouldScrollBottom.current === true) {
            element.scrollTo("top", element.scrollHeight);
        }
    }, [
        messages,
        shouldScrollBottom.current,
        shouldScroolToCurrentMessage.current,
    ]);

    return (
        <Container>
            <ConversationWrapper
                onScroll={(e) => handleOnScroll(e)}
                id={"messagesList"}
                ref={messageListRef}
            >
                {createDateSeparators(messages)?.map((message, index) => {
                    if (message.id) {
                        const isMyMessage = user.id === message.user_id;
                        return (
                            <div key={message.id}>
                                {isMyMessage ? (
                                    <Message
                                        key={message.id}
                                        text={message.original_message}
                                        image={message.image}
                                        status={message.status}
                                        sentTime={moment(message.device_sent_date).format("HH:mm")}
                                        reactions={message.reactions}
                                        messageId={message.id}
                                        conversationId={message.conversation_id}
                                        handleShowFullScreenImg={handleShowFullScreenImg}
                                        handleDeleteMessage={handleDeleteMessage}
                                        messageDeleted={message.deleted}
                                        displayRightClickMenu={displayRightClickMenu}
                                        isMessageRead={message.read}
                                    />
                                ) : (
                                    <MessageReceived
                                        key={message.id}
                                        image={message.image}
                                        messageId={message.id}
                                        conversationId={message.conversation_id}
                                        originalText={message.original_message}
                                        language={message.language_name}
                                        translatedText={findTranslatedTextForUser(
                                            message.message_translations
                                        )}
                                        sentTime={moment(message.device_sent_date).format("HH:mm")}
                                        handleReactToMessage={handleReactToMessage}
                                        reactions={message.reactions}
                                        handleShowFullScreenImg={handleShowFullScreenImg}
                                        messageDeleted={message.deleted}
                                        displayRightClickMenu={displayRightClickMenu}
                                        user={message.user}
                                        selectedConversation={selectedConversation}
                                    />
                                )}
                            </div>
                        );
                    } else {
                        return <DateSeparator key={index} date={message.date} />;
                    }
                })}
                <div ref={bottomRef}></div>
            </ConversationWrapper>
        </Container>
    );
};

export default ConversationMessagesWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.chatBg};
  height: 100vh;
  max-height: calc(100vh - 74px);

  @media (max-width: 1440px) {
    max-height: calc(100vh - 74px);
  }
`;

const ConversationWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
`;
const MessageInputWrapper = styled.div`
  display: flex;
  background: ${colors.userMenuHeaderBg};
  padding: 16px;
  min-height: 74px;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  height: 100%;
  gap: 12px;
`;
const SendMediaButton = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  jusitfy-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
`;
const SendMessageButton = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  jusitfy-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
`;
const MessageInput = styled.input`
  width: 100%;
  border-radius: 100px;
  padding: 10px 16px;
  border: none;
  background: #d9d9d90d;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  &:focus {
    outline: none;
  }
`;
