import { GET } from "../API"


let source;
export const getAverageRatingAPI = (
    onSuccess,
    onError
) => {

    source = GET(`/api/v1/administration/average_conversation_rating`, onSuccess, onError, true, source)
}