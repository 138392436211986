import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import Linkify from "linkify-react";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import FileMessageComponent from "./FileMessageComponent";

const Message = ({
  text,
  image,
  messageId,
  conversationId,
  handleShowFullScreenImg,
  messageDeleted,
  displayRightClickMenu,
  messageObject,
  file,
  video,
}) => {
  let imageHeight;
  let imageWidth = 400;

  const scaledHeightForMaxWidth = () => {
    let aspectRatio = parseFloat(image.width) / parseFloat(image.height);
    let newHeight = 400 / aspectRatio;
    return parseFloat(newHeight);
  };

  const scaledwidthForHeight = (scaledWidth) => {
    let aspectRatio = parseFloat(image.height) / parseFloat(image.width);
    let newWidth = scaledWidth / aspectRatio;
    return parseFloat(newWidth);
  };


  if (image) {
    imageHeight = scaledHeightForMaxWidth();
    imageWidth = scaledwidthForHeight(imageHeight);
  }

  return (
    <Container>
      <MessageWrapper
        onContextMenu={(e) =>
          displayRightClickMenu(e, messageId, conversationId, messageObject)
        }
        imgcontainer={image || video}
      >
        <ItemWrapper>
          {file && <FileMessageComponent file={file} />}

          {image && (
            <ImageWrappr>
              <img
                height={imageHeight}
                width={imageWidth}
                src={image.url}
                alt="pht"
                onClick={() =>
                  handleShowFullScreenImg(image.url, messageId, conversationId)
                }
              />
            </ImageWrappr>
          )}
          {video && (
            <VideoPreview>
              <MediaPlayer
                load="visible"
                posterLoad="visible"
                controls
                playsInline
                src={video.video_url}
              >
                <MediaProvider />
                <DefaultVideoLayout
                  thumbnails={video.thumbnail.url}
                  icons={defaultLayoutIcons}
                />
              </MediaPlayer>
            </VideoPreview>
          )}
          {text.length > 0 && (
            <TextWrapper image={image || video}>
              <Linkify options={{ target: "_blank" }}>
                <p>
                  {!messageDeleted ? (
                    text
                  ) : (
                    <i>{"deleted"}</i>
                  )}
                </p>
              </Linkify>
            </TextWrapper>
          )}
        </ItemWrapper>
      </MessageWrapper>

    </Container>
  );
};

export default Message;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 7px;
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 10px;
  width: fit-content;
  max-width: 400px;
  background: ${colors.purple};
  padding: ${(props) => (props.imgcontainer ? "4px" : "16px")};
  border-top-left-radius: ${(props) => (props.imgcontainer ? "4px" : "20px")};
  border-bottom-left-radius: ${(props) =>
    props.imgcontainer ? "4px" : "20px"};
  border-top-right-radius: ${(props) => (props.imgcontainer ? "4px" : "8px")};
  border-bottom-right-radius: ${(props) =>
    props.imgcontainer ? "4px" : "20px"};

  a {
    color: ${colors.white};
  }
`;
const Details = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;
  width: fit-content;
`;
const Status = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
  svg {
    stroke: ${(props) =>
    props.isread ? colors.purple : colors.messageNotRead};
    height: 100%;
    width: 100%;
  }
`;
const SendTime = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    color: #fafafa4d;
  }
`;
const ReactionContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 25px;
  left: ${(props) => (props.image ? "-20px" : "-12px")};
  bottom: ${(props) => (props.image ? "-15px" : "-12px")};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  background: ${colors.chatElementBg};
`;
const ForwardedInfo = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  margin-top: ${(props) => (props.image ? "6px" : "")};
  padding: ${(props) => (props.image ? "0 8px" : "")};
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: ${colors.white};
  }
  svg {
    max-height: 12px;
  }
`;
const ImageWrappr = styled.div`
  display: flex;
  img {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
`;

const VideoPreview = styled.div`
  max-height: 240px;
  min-height: 240px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const TextWrapper = styled.div`
  padding: ${(props) => (props.image ? "5px 8px" : "")};
  display: flex;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: ${colors.white};
  }

  i {
    font-size: 14px;
    padding: 0 5px;
  }
`;
