import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../Assets/icons/nav-logo-light.svg'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as UsersIcon } from '../../Assets/icons/user-option.svg'
import { ReactComponent as LogsIcon } from '../../Assets/icons/logs-option.svg'
import { ReactComponent as AnalyticsIcon } from '../../Assets/icons/analytics-option.svg'
import { ReactComponent as MessagesIcon } from '../../Assets/icons/messages-option.svg'
import { ReactComponent as LogoutIcon } from '../../Assets/icons/logout-icon.svg'
import { ReactComponent as ConfigurationIcon } from '../../Assets/icons/configurationIcon.svg'
import { ReactComponent as LlmIcon } from '../../Assets/icons/llmIcon.svg'
import { ANALYTICS_OPTION, CONFIGURATION_OPTION, LLM_OPTION, LOGS_OPTION, MESSAGES_OPTION, PROFILE_OPTION, USER_OPTION } from '../../Constants/OptionsConstants'
import { useAuthService } from '../../Context/AuthContext'
import LocalStorage from '../../Helpers/LocalStorage'

const NavBarButton = ({ icon, text, selected, onClick }) => {
    return (
        <NavBarButtonWrapper selected={selected} onClick={onClick}>
            <BtnOptionSvg selected={selected}>{icon}</BtnOptionSvg>
            <p>{text}</p>
        </NavBarButtonWrapper>
    )
}

const SideNavBar = ({ option, setOption }) => {
    const { logout_admin } = useAuthService();
    const handleLogout = () => {
        logout_admin(
            LocalStorage.getAuthenticationToken(),
            function (response) {
            },
            function (error) {
                console.log(error)
            },

        )
    }
    return (
        <NavBarContainer>
            <LogoWrapper>
                <Logo />
            </LogoWrapper>

            <OptionsWrapper>
                <OptionsList>
                    <NavBarButton
                        text={"Users"}
                        icon={<UsersIcon />}
                        selected={option === USER_OPTION}
                        onClick={() => setOption(USER_OPTION)}
                    />

                    {/* TODO: uncomment for logs */}
                    {/* <NavBarButton
                        text={"Logs"}
                        icon={<LogsIcon />}
                        selected={option === LOGS_OPTION}
                        onClick={() => setOption(LOGS_OPTION)}
                    /> */}
                    <NavBarButton
                        text={"Analytics"}
                        icon={<AnalyticsIcon />}
                        selected={option === ANALYTICS_OPTION}
                        onClick={() => setOption(ANALYTICS_OPTION)}
                    />
                    <NavBarButton
                        text={"Messages"}
                        icon={<MessagesIcon />}
                        selected={option === MESSAGES_OPTION}
                        onClick={() => setOption(MESSAGES_OPTION)}
                    />
                    <NavBarButton
                        text={"Configuration"}
                        icon={<ConfigurationIcon />}
                        selected={option === CONFIGURATION_OPTION}
                        onClick={() => setOption(CONFIGURATION_OPTION)}
                    />
                    <NavBarButton
                        text={"LLM"}
                        icon={<LlmIcon />}
                        selected={option === LLM_OPTION}
                        onClick={() => setOption(LLM_OPTION)}
                    />
                </OptionsList>

                <BottomOptions>
                    <NavBarButton
                        text={"Profile"}
                        selected={option === PROFILE_OPTION}
                        onClick={() => setOption(PROFILE_OPTION)}
                    />
                    <NavBarButton
                        text={"Logout"}
                        icon={<LogoutIcon />}
                        selected={true}
                        onClick={handleLogout}
                    />
                </BottomOptions>
            </OptionsWrapper>

        </NavBarContainer>
    )
}

export default SideNavBar

const NavBarContainer = styled.div`
display:flex;
flex-direction:column;
padding:0 40px;
height:100%;
position:relative;
height:100vh;
min-width:250px;
`
const LogoWrapper = styled.div`
display:flex;
align-items:center;
padding:25px 0;
`

const OptionsWrapper = styled.div`
display:flex;
flex-direction:column;
padding:100px 0;
height:100%;
position:relative;
overflow-y:auto;
`
const OptionsList = styled.div`
display:flex;
flex-direction:column;
gap:30px;
position:relative;
`
const BottomOptions = styled(OptionsList)`
min-height:200px;
justify-content:end;
margin-top:auto;
`

const NavBarButtonWrapper = styled.button`
cursor:pointer;
width:fit-content;
display:flex;
align-items:center;
background:none;
border:none;
gap:10px;

p{
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${props => props.selected ? colors.navBar.selctedColor : colors.navBar.color};
}
`
const BtnOptionSvg = styled.div`
display:flex;
align-items:center;
svg{
    height:24px;
    max-width:24px;
    color:${props => props.selected ? colors.navBar.selctedColor : colors.navBar.color};
 }
`