import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import { ReactComponent as SendMessageIcon } from '../../Assets/icons/sendMessageButton.svg'

import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';


const SendMedia = ({
    mediaInput,
    setMediaInput,
    handleSendMedia,
    mediaInputFileRef,
    handleInputChange,
    inputValue,
    messageInputRef
}) => {

    const [src, setSrc] = useState({
        src: null,
        type: null
    });

    const handleClose = () => {
        mediaInputFileRef.current.value = null;
        setMediaInput(null);
    }


    const createPreview = (mediaInput) => {

        if (!mediaInput) {
            return
        }
        let type = mediaInput.type.split('/')[0];
        var preview = URL.createObjectURL(mediaInput)
        if (type === "video") {
            setSrc({
                src: preview,
                type: "video/mp4"
            });

        } else {
            setSrc({
                src: preview,
                type: mediaInput.type
            });
        }


    }




    const generateVideoThumbnail = (file) => {

        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };

    const generateThumbnail = async (file) => {
        const thumbnail = await generateVideoThumbnail(file);
        return thumbnail
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleSendVideo = async (media, message) => {
        await generateThumbnail(media).then(
            (thumbnail) => {
                let thumb = dataURLtoFile(thumbnail, "thumbnail")
                handleSendMedia("video", media, message, thumb);
            }
        );

    }

    const handleSend = (type, media, message) => {
        if (type.includes("video")) {
            handleSendVideo(media, message)
        } else {
            handleSendMedia("image", media, message, null);
        }

        handleClose();
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSend(src.type, mediaInput, inputValue)
        }
    }

    useEffect(() => {
        createPreview(mediaInput)
    }, [mediaInput])


    return (
        <Container>
            <Top>
                <CloseBtn onClick={() => handleClose()}>
                    <CloseIcon />
                </CloseBtn>
                <Title>
                    <h1>{"Send media"}</h1>
                </Title>
                <div></div>
            </Top>

            {src?.type?.includes("video") ?
                <VideoPreview>
                    <MediaPlayer
                        load='visible'
                        posterLoad='visible'
                        controls
                        playsInline
                        src={src}
                    >
                        <MediaProvider />
                        <DefaultVideoLayout icons={defaultLayoutIcons} />
                    </MediaPlayer>
                </VideoPreview> :
                <ImagePreview>
                    <img src={src.src} alt='prevs' />
                </ImagePreview>
            }

            <MediaSendDetails>
                <SendTo>
                    <h3>{""}</h3>
                </SendTo>

            </MediaSendDetails>
            <MessageInputWrapper
                onKeyDown={(e) => handleKeyDown(e)}
            >

                <MessageInput
                    autoFocus
                    ref={messageInputRef}
                    placeholder={"Add caption"}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e)}
                />
                <SendMessageButton onClick={() => handleSend(src.type, mediaInput, inputValue)}>
                    <SendMessageIcon />
                </SendMessageButton>

            </MessageInputWrapper>
        </Container>
    )
}

export default SendMedia

const Container = styled.div`
display:flex;
flex-direction:column;
position:absolute;
top:0;
bottom:0;
height:100%;
background:${colors.userMenuHeaderBg};
width:100%;
overflow:hidden;
z-index:10;
`
const Top = styled.div`
display:flex;
justify-content:space-between;
padding:16px;
min-height:74px;
height:74px;
position:relative;
`
const MediaSendDetails = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
padding:16px;
min-height:56px;
height:56px;
position:relative;
border-bottom:1px solid ${colors.darkPurple};
`
const ImagePreview = styled.div`
display:flex;
height:100%;
width:100%;
max-height:calc(100% - 204px);
background:${colors.chatBg};
justify-content:center;
align-items:center;
position:relative;
img{
    position:relative;
    object-fit:contain;
    widht:100%;
    height:100%;
}
`
const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
`
const Title = styled.div`
align-items:center;
display:flex;
h1{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
}
`

const SendMessageButton = styled.button`

width:42px;
height:42px;
border-radius:100%;
display:flex;
jusitfy-content:center;
align-items:center;
cursor:pointer;
border:none;
background:none;
&:disabled{
opacity:0.5;
cursor:inherit;
}
`

const SendTo = styled.div`
display:flex;
align-items:center;
gap:10px;
h2{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color:${colors.white};
}
h3{
    font-size:14px;
    font-weight:500;
    line-height: 20px;
    color:${colors.white};
    opacity:0.5;
}
`
const MessageInput = styled.input`
width:100%;
border-radius:100px;
padding:10px 16px;
border:none;
background:#D9D9D90D;
color:${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
&:focus{
outline:none;
}
`
const MessageInputWrapper = styled.div`
display:flex;
background:${colors.userMenuHeaderBg};
padding:16px;
min-height:74px;
height:74px;
min-height:74px;
max-height:74px;
height:100%;
gap:12px;

`
const VideoPreview = styled.div`
display:flex;
height:100%;
width:100%;
max-height:calc(100% - 204px);
background:${colors.chatBg};
justify-content:center;
align-items:center;
position:relative;
overflow:hidden;
`