
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import CustomDatePicker from '../DashboardComponents/CustomDatePicker'

import { ReactComponent as BackArrow } from '../../Assets/icons/backArrow.svg'
import { ReactComponent as ForwardArrow } from '../../Assets/icons/forwardArrow.svg'
import MessageImageElement from './MessageImageElement'
import AddNewMessageModal from './AddNewMessageModal'
import { notifyError } from '../../Helpers/Notifications'
import { useMessageToUsersService } from '../../Context/MessageToUsersContext'
import MediaViewModal from './MediaViewModal'
import moment from 'moment'
import { style } from '@mui/system'

const MessagePageCurrentUsers = ({ setIsLoading }) => {

    const { get_old_user_message } = useMessageToUsersService();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState();
    const [videoPreview, setVideoPreview] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [oldUsersMessages, setOldUsersMessages] = useState([]);
    const shouldRefresh = useRef(false);



    const handleAddNewMessageBtn = () => {
        setIsModalOpen(true);
    }

    const handleImageBtn = (image) => {
        setIsImageModalOpen(true);
        setImagePreview(image)
    }

    const handleVideoBtn = (video) => {
        console.log(video)
        setIsImageModalOpen(true);
        setVideoPreview(video)
    }
    const handleFileBtn = (file) => {
        setIsImageModalOpen(true);
        setFilePreview(file)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleCloseImageModal = () => {
        setIsImageModalOpen(false);
        setImagePreview(null)
        setVideoPreview(null)
        setFilePreview(null)
    }

    const resetDateFilters = () => {
        setStartDate(null)
        setEndDate(null)
    }


    const handleGetMessages = (page, startDate = null, endDate = null) => {
        setIsLoading(true)
        let dataObj = {
            page: page
        }

        if (startDate) {
            dataObj.registration_start_date = moment(startDate).startOf("day").toISOString();
        }
        if (endDate) {
            dataObj.registration_end_date = moment(endDate).endOf("day").toISOString();
        }


        get_old_user_message(
            dataObj,
            function (response) {
                setIsLoading(false)
                const messages = response.data.old_users_sent_messages
                setOldUsersMessages(messages)
            },
            function (error) {
                setIsLoading(false)
                console.log(error)
                notifyError("Something wrong")
            }
        )
    }

    useEffect(() => {
        handleGetMessages(page, startDate, endDate)
    }, [page, startDate, endDate])


    useEffect(() => {
        if (shouldRefresh.current) {
            handleGetMessages(1)
            shouldRefresh.current = false
        }
    }, [shouldRefresh.current])

    return (
        <MessagePageCurrentUsersContainer>
            {isModalOpen &&
                <AddNewMessageModal
                    handleCloseModal={handleCloseModal}
                    shouldRefresh={shouldRefresh}
                    setIsLoading={setIsLoading}
                />
            }
            {
                isImageModalOpen &&
                <MediaViewModal
                    handleCloseModal={handleCloseImageModal}
                    imagePreview={imagePreview}
                    videoPreview={videoPreview}
                    filePreview={filePreview}
                />
            }

            <TitleWrapper>
                <Title><h1>User Messages</h1></Title>
                <AddNewMessageBtn onClick={() => handleAddNewMessageBtn()}>Add new message</AddNewMessageBtn>
            </TitleWrapper>
            <DateWrapper>
                <DateGroup>
                    <p>Start date</p>
                    <CustomDatePicker
                        showIcon
                        value={startDate}
                        setValue={(date) => setStartDate(date)}
                    />
                </DateGroup>
                <DateGroup>
                    <p>End date</p>
                    <CustomDatePicker
                        showIcon
                        value={endDate}
                        setValue={(date) => setEndDate(date)}
                    />
                </DateGroup>


                <ClearDateBtn style={{ marginTop: "auto" }} onClick={() => resetDateFilters()}>
                    Clear date
                </ClearDateBtn>

            </DateWrapper>

            <MessageListWrapper>
                <SubTitle><h1>Previous Sent Messages</h1></SubTitle>

                <MessageList>
                    <MessageListHeader>
                        <HeaderCell style={{ maxWidth: "100px" }}>
                            <p>Send Date</p>
                        </HeaderCell>
                        <HeaderCell style={{ minWidth: "350px" }}>
                            <p>Message or images</p>
                        </HeaderCell>
                        <HeaderCell style={{ flexDirection: "column", maxWidth: "180px" }}>
                            <p>User Registration </p>
                            <p>Start Date - End Date </p>
                        </HeaderCell>
                    </MessageListHeader>

                    <MessageListContent>
                        {oldUsersMessages.map((message, index) => (
                            <MessageImageElement
                                key={index}
                                sendDate={message.device_sent_date}
                                message={message.original_message}
                                image={message.image}
                                userRegistrationStartDate={message.registration_start_date}
                                userRegistrationEndDate={message.registration_end_date}
                                handleImageBtn={handleImageBtn}
                                video={message.video}
                                file={message.document}
                                handleFileBtn={handleFileBtn}
                                handleVideoBtn={handleVideoBtn}
                            />
                        ))}


                    </MessageListContent>


                </MessageList>

                <PaginationWrapper>
                    <Pagination>
                        {page != 1 &&
                            <PaginationBtn onClick={() => setPage(page - 1)}>
                                <BackArrow />
                            </PaginationBtn>}

                        <PageNumber>
                            <p>{page}</p>
                        </PageNumber>
                        {page > 0 && oldUsersMessages.length == 25 &&
                            <PaginationBtn onClick={() => setPage(page + 1)}>
                                <ForwardArrow />
                            </PaginationBtn>}

                    </Pagination>

                </PaginationWrapper>

            </MessageListWrapper>

        </MessagePageCurrentUsersContainer>
    )
}

export default MessagePageCurrentUsers

const MessagePageCurrentUsersContainer = styled.div`
display:flex;
flex-direction:column;
gap:35px;
height:calc(100vh - 75px);
overflow:auto;
position:relative;
padding:35px 50px
`

const Title = styled.div`
h1{
font-size: 25px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
`
const SubTitle = styled(Title)`
h1{
    font-size: 20px;
    line-height: 30px;
}
`
const TitleWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`
const AddNewMessageBtn = styled.button`
font-size: 16px;
font-weight: 600;
line-height: 22px;
padding:11px 26px;
background:${colors.dashBoard.purple};
color:${colors.white};
border:none;
border-radius:10px;
cursor:pointer;
`
const DateWrapper = styled.div`
display:flex;
gap:20px;
p{
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${colors.white};
}
`
const DateGroup = styled.div`
display:flex;
flex-direction:column;
gap:10px;
min-width:250px;
`
const MessageListWrapper = styled.div`
display:flex;
flex-direction:column;
gap:30px;
margin-top:20px;
height:100%;

`

const MessageList = styled.div`
display:flex;
flex-direction:column;
background:${colors.dashBoard.elementBg};
border-radius:10px;
padding:20px 30px;
position:relative;

`

const PaginationWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;

`
const PaginationBtn = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:${colors.dashBoard.purple};
min-width:40px;
min-height:40px;
cursor:pointer;

svg{
   color:${colors.white};
}
`
const Pagination = styled.div`
border-radius:4px;
overflow:hidden;
display:flex;
align-items:center;
justify-content:center;
background:${colors.dashBoard.purple};
`

const PageNumber = styled.div`
display:flex;
align-items:center;
justify-content:center;
background:${colors.dashBoard.purple};
min-width:45px;
min-height:40px;
border-left:1px solid rgba(250, 250, 250, 0.2);
border-right:1px solid rgba(250, 250, 250, 0.2);
margin:5px 0;
p{
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color:${colors.dashBoard.elementColor};
}
`

const MessageListHeader = styled.div`
display:flex;
width:100%;
gap:20px;
`

const HeaderCell = styled.div`
display:flex;
align-items:center;
width:100%;
min-width:100px;
p{
    width:100%;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:${colors.dashBoard.purple};
}
`

const MessageListContent = styled.div`
display:flex;
flex-direction:column;
height:100%;
overflow:auto;

`
const ClearDateBtn = styled.button`
font-size: 16px;
font-weight: 500;
line-height: 22px;
padding:11px 0;
color:${colors.purple};
background:none;
border:none;
border-radius:10px;
cursor:pointer;
`