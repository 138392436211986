import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import moment from 'moment'

const MessageImageElement = ({
    sendDate,
    message,
    image,
    userRegistrationStartDate,
    userRegistrationEndDate,
    handleImageBtn,
    video,
    handleVideoBtn,
    file,
    handleFileBtn,
}) => {
    if (userRegistrationStartDate) {
        var user_registration_start_date = moment(userRegistrationStartDate).format("YYYY-MM-DD")
    }
    if (userRegistrationEndDate) {
        var user_registration_end_date = moment(userRegistrationEndDate).format("YYYY-MM-DD")
    }

    return (
        <Container>
            <Cell style={{ maxWidth: "100px" }}>
                <p>{moment(sendDate).format("YYYY-MM-DD")}</p>
            </Cell>
            <Cell style={{ minWidth: "350px" }}>
                {image &&
                    <ImageBtn onClick={() => handleImageBtn(image)}>
                        Image
                    </ImageBtn>

                }
                {video &&
                    <ImageBtn onClick={() => handleVideoBtn(video)}>
                        Video
                    </ImageBtn>
                }
                {file &&
                    <ImageBtn onClick={() => handleFileBtn(file)}>
                        File
                    </ImageBtn>
                }
                <p>{message}</p>
            </Cell>
            <Cell style={{ maxWidth: "180px" }}>
                <p>{user_registration_start_date} - {user_registration_end_date}</p>
            </Cell>

        </Container>
    )
}

export default MessageImageElement

const Container = styled.div`
display:flex;
align-items:center;
width:100%;
gap:20px;
padding:10px 0;

border-bottom:1px solid rgba(54, 62, 81, 0.5);


`
const Cell = styled.div`
display:flex;
align-items:center;
width:100%;
min-width:100px;
gap:10px;
p{
    width:100%;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color:${colors.white};
}
`
const ImageBtn = styled.button`
background:${colors.dashBoard.inputBg};
padding:10px 15px;
border:1px solid white;
color:white;
border-radius:4px;
cursor:pointer;
`