export const mapModelResponseForSelectMenu = (models) => {
    let mappedModels = models.map((model) => {
        return {
            value: model.name,
            label: model.name,
            id: model.id,
            is_default: model.is_default
        }
    })
    return mappedModels
}