import { POST } from "../API"


export const setUserBlockAPI = (
    user_id,
    blocked,
    onSuccess,
    onError
) => {
    const data = {
        user_id: user_id,
        blocked: blocked
    }

    POST(`/api/v1/administration/users/set_user_blocked`, data, onSuccess, onError, true)
}