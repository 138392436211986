import React from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { ReactComponent as CalendarIcon } from "../../Assets/icons/calendar.svg";

import { colors } from "../../Assets/styles/colors";
const CustomDatePicker = ({ value, setValue, format, slim }) => {
    return (
        <Container slim={slim}>
            <DatePicker
                format={format ? format : "dd/MM/yyyy"}
                value={value}
                onChange={setValue}
                clearIcon={false}
                calendarIcon={<CalendarIcon />
                }
            >
            </DatePicker>
        </Container >
    );
};

export default CustomDatePicker;
const Container = styled.div`
  display: flex;
  width: 100%;
  z-index:2;

  .react-date-picker{
    width:100%;
    color:white;
    
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: none;
    padding: ${props => props.slim ? "0" : "8px 16px "};
    color: white;
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
  }

  .react-date-picker react-date-picker--closed react-date-picker--enabled{
    width:100%;
  }
  .react-date-picker__inputGroup__input{
   
  }
  
.react-calendar{
    background: ${colors.newChatModalInputBg};
    border:none;
    border-radius:10px;
}
.react-calendar__month-view__days__day{
    color:white;
}
.react-calendar__month-view__days__day--weekend{
    color:red;
}
.react-calendar__tile{
    border-radius:10px;
}
.react-calendar__tile--active{
background:${colors.purple};
}
.react-calendar__navigation button{
    color:${colors.purple};

}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
    background:${colors.background};
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
    background:${colors.background};
}

.react-calendar__year-view__months__month{
    color:white;
}
.react-calendar__tile--hasActive{
    background:${colors.purple};
}
.react-calendar__tile--now {
    background:${colors.chatElementBgSelected};
}

`;
