import React, { useState } from 'react'
import styled from 'styled-components'
import SideNavBar from '../Components/SideNavBar/SideNavBar'
import DashBoardContent from '../Components/DashboardComponents/DashBoardContent'
import { USER_OPTION } from '../Constants/OptionsConstants'

const DashBoard = ({ setIsLoading }) => {

    const [option, setOption] = useState(USER_OPTION)

    return (
        <Container>
            <SideNavBar
                option={option}
                setOption={setOption}
            />
            <DashBoardContent option={option} setIsLoading={setIsLoading} />
        </Container>
    )
}

export default DashBoard
const Container = styled.div`
display:flex;
position:relative;
width:100%;

`