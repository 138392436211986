import { POST } from "../API"


export const deleteUserAPI = (
    user_id,
    onSuccess,
    onError
) => {
    const data = {
        user_id: user_id
    }

    POST(`/api/v1/administration/delete_user`, data, onSuccess, onError, true)
}