import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'

const EmptyConversation = () => {
    return (
        <Centered>
            <Container>
            </Container>
        </Centered>
    )
}

export default EmptyConversation
const Centered = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:100%;
background:${colors.chatBg};
`
const Container = styled.div`
position:relative;
display:flex;
flex-direction:column;
gap:15px;

width:335px;
align-items:center;

h1{
    color:${colors.white};
font-size: 32px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: center;

}
p{
    color:#FFFFFFB2;
    font-family: Inter;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0px;
text-align: center;
}
`