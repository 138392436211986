import { GET } from "../API"



export const getCurrentUserAPI = (
    onSuccess,
    onError
) => {

    GET(`/api/v1/identity/admins/current_user_details`, onSuccess, onError, true)
}