import React from 'react'
import styled from 'styled-components'

import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'


const ContactElement = ({ item, handleRemove, withConfirmationDelete, variant }) => {
  return (
    <Contact>
      <ContactAvatar>
        {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
      </ContactAvatar>
      <ContactName>
        <p>{item.name}</p>
      </ContactName>
    </Contact>
  )
}

export default ContactElement


const Contact = styled.div`
display:flex;
align-items:center;
gap:10px;

`
const ContactName = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
border-bottom:1px solid rgba(54, 62, 81, 0.5);
width:100%;
p{
font-size: 16px;
font-weight: 400;
line-height: 35px;
text-align: left;
color:${colors.white};
}
padding-right:16px;
`
const RemoveButton = styled.button`
background:none;
border:none;
display:flex;
align-items:center;
cursor:pointer;
svg{
    height:24px;
}
`
const ContactAvatar = styled.div`
  display: flex;
  height:35px;
  width:35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
//   height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
     object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;