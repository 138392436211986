import axios from "axios";
import LocalStorage from "../../Helpers/LocalStorage";

const URL = process.env.REACT_APP_API_URL;

var refreshTokenConfig = (refreshtoken, token) => {
  var config = {
    method: "post",
    url: `${URL}/oauth/token`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: {
      grant_type: "refresh_token",
      refresh_token: refreshtoken,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    },
  };
  return config;
};

export const refreshToken = (refreshtoken, token, onSuccess, onError) => {
  axios(refreshTokenConfig(refreshtoken, token))
    .then(function (response) {
      LocalStorage.setAuthenthicationToken(response.data.access_token);
      LocalStorage.setRefreshToken(response.data.refresh_token);
      onSuccess(response);
    })
    .catch(function (error) {
      onError(error);
    });
};
