import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../Assets/styles/colors";
import ConversationsList from "../Components/ConversationsView/ConversationsList";
import { getUserConversationsAPI } from "../API/users/getUserConversations";
import { notifyError, notifySuccess } from "../Helpers/Notifications";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { getMessagesForConversationAPI } from "../API/users/getMessagesForConversation";
import { ReactComponent as ArrowRight } from "../Assets/icons/forwardArrow.svg";
import ConversationHeader from "../Components/ConversationsView/ConversationHeader";
import ConversationMessagesWrapper from "../Components/ConversationsView/ConversationMessagesWrapper";
import FullscreenImage from "../Components/ConversationsView/FullscreenImage";
import {
    handleReceivedMessages,
    sortNewestConversations,
    sortReceivedMessages,
} from "../Helpers/chatHelper/ChatHelper";
import ChatDetailsHeader from "../Components/ConversationsView/ChatDetailsHeader";
import GroupDetailsView from "../Components/ConversationsView/GroupDetailsView";
import ChatDetailsView from "../Components/ConversationsView/ChatDetailsView";
import { getUserDetailsAPI } from "../API/users/getUserDetails";
import EmptyConversation from "../Components/ConversationsView/EmptyConversation";
import { setUserBlockAPI } from "../API/users/blockUnblockUser";
import DeleteConfirmationView from "../Components/DashboardComponents/DeleteConfirmationView";

const ConversationsView = ({ setIsLoading }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState(null)
    const [searchInput, setSearchInput] = useState("");
    const [conversations, setConversations] = useState();
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [currentConversationMessages, setCurrentConversationMessages] =
        useState([]);

    const [userId, setUserId] = useState(null);

    const [fullScreenImg, setFullScreenImg] = useState(null);
    const [showFullScreenImg, setShowFullScreenImg] = useState(false);
    const [showGroupChatDetails, setShowGroupChatDetails] = useState(false)

    const shouldScrollBottom = useRef(true);
    const shouldScroolToCurrentMessage = useRef(false);
    const shouldLoadMoreMessages = useRef(true);
    const messagePage = useRef(1);

    const [scrollHeight, setScrollHeight] = useState(0);

    const initializeValueFromParams = () => {
        if (location.search != "") {
            let params = qs.parse(location.search);
            let userId = params.userId;
            setUserId(parseInt(userId));
        }
    };

    const getCurrentUserDetails = (userId) => {
        setIsLoading(true);
        getUserDetailsAPI(
            userId,
            function (response) {
                setIsLoading(false);
                const user = response.data.user
                setSelectedUser(user)
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong");
                console.log(error);
            }
        )
    }

    const getUserConversations = (userId) => {
        setIsLoading(true);
        getUserConversationsAPI(
            userId,
            function (response) {
                setIsLoading(false);
                let conversations = response.data.conversations;
                let sortedConversation = sortNewestConversations(conversations);
                setConversations(sortedConversation);
            },
            function (error) {
                setIsLoading(false);
                notifyError("Something wrong");
                console.log(error);
            }
        );
    };

    const loadMessageForConversation = (userId, conversationId, page) => {
        setIsLoading(true);
        getMessagesForConversationAPI(
            userId,
            conversationId,
            page,
            function (response) {
                let responseMessages = response.data.messages;
                shouldLoadMoreMessages.current = !(response.data.messages.length < 20);
                let filteredMessages;
                if (page !== 1) {
                    shouldScrollBottom.current = false;
                    shouldScroolToCurrentMessage.current = true;
                    filteredMessages = handleReceivedMessages(
                        currentConversationMessages,
                        responseMessages
                    );
                } else {
                    filteredMessages = handleReceivedMessages([], responseMessages);
                }
                const sortedMessages = sortReceivedMessages(filteredMessages);
                // currentMessagesRef.current = sortedMessages
                setCurrentConversationMessages(sortedMessages);
                setIsLoading(false);
            },
            function (error) {
                console.log(error, "error getMessageForConversation");
                setIsLoading(false);
            }
        );
    };

    const handleBlockUser = (userId, blocked) => {
        setIsLoading(true)
        setUserBlockAPI(
            userId,
            blocked,
            function (response) {
                const newUser = response.data.user;;
                setSelectedUser(newUser);
                setIsLoading(false)
            },
            function (error) {
                console.log(error);
                notifyError("Something wrong")
                setIsLoading(false)
            }
        )
    }

    const handleBlockWithConfirmation = (userIf) => {
        handleBlockUser(userId, true)
    }

    const handleSelectConversation = (conversation) => {
        shouldScrollBottom.current = true;
        shouldScroolToCurrentMessage.current = false;
        messagePage.current = 1;
        //currentConversationRef.current = conversation;
        setSelectedConversation(conversation);
    };

    const handleOnScroll = (e) => {
        if (e.currentTarget.scrollTop === 0) {
            if (shouldLoadMoreMessages.current) {
                setScrollHeight(e.currentTarget.scrollHeight);
                messagePage.current += 1;
                loadMessageForConversation(
                    userId,
                    selectedConversation.id,
                    messagePage.current
                );
            }
        }
    };

    const handleShowFullScreenImg = (image, messageId, conversationId) => {
        setFullScreenImg({
            image: image,
            messageId: messageId,
            conversationId: conversationId,
        });
        setShowFullScreenImg(true);
    };

    const handleCloseFullScreenImg = () => {
        setShowFullScreenImg(false);
        setFullScreenImg(null);
    };

    const handleShowGroupChatDetails = () => {
        setShowGroupChatDetails(!showGroupChatDetails)
    }



    useEffect(() => {
        initializeValueFromParams();
    }, []);



    useEffect(() => {
        if (!userId) {
            return;
        }
        getCurrentUserDetails(userId)
    }, [userId]);

    useEffect(() => {
        if (!selectedUser) {
            return;
        }
        getUserConversations(selectedUser.id);
    }, [selectedUser?.id])

    useEffect(() => {
        if (!selectedConversation) {
            return;
        }
        loadMessageForConversation(selectedUser.id, selectedConversation.id, 1);
    }, [selectedConversation?.id]);

    return (
        <Container>
            <Left>
                <LeftSideHeader>
                    <BackBtn onClick={() => navigate("/")}>
                        <ArrowRight style={{ rotate: "180deg" }} />
                        Back
                    </BackBtn>
                    <SelectedUserWrapper>
                        <p>{selectedUser?.name}</p>
                    </SelectedUserWrapper>
                    {
                        selectedUser?.blocked ?
                            <BlockBtn onClick={() => handleBlockUser(selectedUser.id, false)}>Unblock User</BlockBtn> :
                            <DeleteConfirmationView
                                item={selectedUser?.id}
                                handleRemoveItem={handleBlockWithConfirmation}
                                variant={"block-user"}
                            />

                    }

                </LeftSideHeader>
                <ConversationsList
                    userId={userId}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    conversations={conversations}
                    selectedConversation={selectedConversation}
                    handleSelectConversation={handleSelectConversation}
                />
            </Left>
            <Right>
                {selectedConversation ? <ConversationWrapper>
                    <ConversationHeader

                        selectedConversation={selectedConversation}
                        onClick={() => handleShowGroupChatDetails()}
                    />
                    <ConversationMessagesWrapper
                        key={"View"}
                        user={selectedUser}
                        handleOnScroll={handleOnScroll}
                        shouldScrollBottom={shouldScrollBottom}
                        shouldScroolToCurrentMessage={shouldScroolToCurrentMessage}
                        messages={currentConversationMessages}
                        selectedConversation={selectedConversation}
                        handleShowFullScreenImg={handleShowFullScreenImg}
                        scrollHeight={scrollHeight}
                    //inputValue={messageInput}
                    //handleInputChange={handleMessageInputChange}
                    // handleSendMessage={handleSendMessage}
                    // handleSendImage={handleSendImage}
                    // handleReactToMessage={handleReactToMessage}
                    // handleDeleteMessage={handleDeleteMessage}
                    // displayRightClickMenu={displayRightClickMenu}
                    // inputFileRef={inputFileRef}
                    />
                </ConversationWrapper>
                    :
                    <EmptyConversation />

                }

            </Right>

            {showFullScreenImg && (
                <FullscreenImage
                    // handleDeletePhoto={handleDeletePhoto}
                    image={fullScreenImg.image}
                    conversationId={fullScreenImg.conversationId}
                    messageId={fullScreenImg.messageId}
                    handleCloseFullScreenImg={handleCloseFullScreenImg}
                />
            )}

            {showGroupChatDetails &&
                <ChatDetailsWrapper>
                    <ChatDetailsHeader selectedConversation={selectedConversation} handleClose={() => handleShowGroupChatDetails()} />
                    {selectedConversation.group ?
                        <GroupDetailsView
                            user={selectedUser}
                            groupData={selectedConversation}
                        />
                        :
                        <ChatDetailsView
                            conversationData={selectedConversation.other_participants[0]}
                        />


                    }

                </ChatDetailsWrapper>}
        </Container>
    );
};

export default ConversationsView;
const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 460px;
  min-width: 400px;
  width: 100%;
  background: ${colors.conversationView.chatsListBg};
  position: relative;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background: ${colors.conversationView.userMenuHeaderBg};
`;

const BackBtn = styled.button`
  display: flex;
  align-items: center;
  border: none;
  color: ${colors.conversationView.purple};
  background: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;

  svg{
   color: ${colors.conversationView.purple};
  }
`;
const BlockBtn = styled(BackBtn)`
color:white;
  background: ${colors.conversationView.errorColor};
`;
const ConversationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 400px;
`;


const ChatDetailsWrapper = styled.div`

width:100%;
max-width:350px;

position:relative;
border-left:1px solid ${colors.chatBg};
background:${colors.chatsListBg};

`
const SelectedUserWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;

padding:8px 10px;
border-radius:8px;
p{
    font-size:16px;
    font-weight:500;
    line-height:18px;
    color:${colors.chatElementColor};
}
`