import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as SearchIcon } from '../../Assets/icons/searchIcon.svg'
const SearchMenu = ({ value, setvalue }) => {

    const handleInputChange = (e) => {
        setvalue(e.target.value)
    }
    const handleEsc = (e) => {
        if (e.key === "Escape") {
            setvalue("")
        }
    }

    return (
        <SearchContainer>
            <SearchIcon />
            <input
                onKeyDown={(e) => handleEsc(e)}
                value={value}
                placeholder='Search'
                onChange={(e) => handleInputChange(e)}></input>
        </SearchContainer>
    )
}

export default SearchMenu
const SearchContainer = styled.div`
background:#7676803D;
padding:10px 8px;
border-radius:10px;
align-items:center;
display:flex;
gap:5px;
input{
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${colors.white};
background:none;
width:100%;
border:none;

&:focus{
    outline:none;
}
}
`