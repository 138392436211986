
import React from 'react'
import styled from 'styled-components'
import { Popover } from 'react-tiny-popover'
import { ReactComponent as SendAttachments } from '../../Assets/icons/AttachmentIcon.svg'
import { ReactComponent as SendMessageIcon } from '../../Assets/icons/sendMessageButton.svg'
import { ReactComponent as FileIcon } from '../../Assets/icons/context-menu-icons/FilesIcon.svg'
import { ReactComponent as MediaIcon } from '../../Assets/icons/context-menu-icons/MediaIcon.svg'
import { colors } from '../../Assets/styles/colors'
import AttachmentMenu from './AttachmentMenu'


const InputLine = ({
    mediaInputFileRef, fileInputRef, messageInputRef,
    handleMediaInputFileChange, handleInputFileChange,
    inputValue, setInputValue,
    isAttachmentMenuOpen, setIsAttachmentMenuOpen,
    handleOpenUploadMedia, handleOpenUploadFile,
    showEditMessageModal, handleCloseEdit,
    showReplyMessageModal, handleCloseReply,
    handleSendButton,
    onKeyDown
}) => {

    const attachmentOptionsList = [
        {
            text: "Media",
            icon: <MediaIcon />,
            action: () => handleOpenUploadMedia(),
        },
        {
            text: "Files",
            icon: <FileIcon />,
            action: () => handleOpenUploadFile(),
        },
    ]


    const handleEsc = (e) => {
        if (showEditMessageModal) {
            if (e.key === "Escape") {
                handleCloseEdit();
            }

        }

        if (showReplyMessageModal) {
            if (e.key === "Escape") {
                handleCloseReply()
            }
        }


    }

    return (
        <Container onKeyDown={(e) => onKeyDown(e)}>
            <input
                type="file"
                hidden
                ref={mediaInputFileRef}
                onChange={(e) => handleMediaInputFileChange(e)}
                accept="video/*,image/* "
            />
            <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={(e) => handleInputFileChange(e)}
                accept="application/*"
            />

            <Popover
                isOpen={isAttachmentMenuOpen}
                onClickOutside={() => setIsAttachmentMenuOpen(false)}
                positions={["top"]}
                padding={10}
                transform={{ left: 70, }}
                transformMode='relative'
                content={
                    <AttachmentMenu
                        optionsList={attachmentOptionsList}
                    />
                }
            >
                <AttachmentButton onClick={(e) => setIsAttachmentMenuOpen(true)}>
                    <SendAttachments />
                </AttachmentButton>
            </Popover>

            <MessageInput
                onKeyDown={(e) => handleEsc(e)}
                ref={messageInputRef}
                placeholder={"Type your message"}
                value={inputValue}
                onChange={(e) => setInputValue(e.currentTarget.value)}
            />
            <SendMessageButton disabled={inputValue?.length == 0} onClick={() => handleSendButton()}>
                <SendMessageIcon />
            </SendMessageButton>
        </Container>
    )
}

export default InputLine

const Container = styled.div`
display:flex;
background:${colors.userMenuHeaderBg};
padding:16px;
min-height:74px;
max-height:74px;
height:100%;
width:100%;
gap:12px;
`
const AttachmentButton = styled.button`
width:42px;
height:42px;
border-radius:100%;
display:flex;
jusitfy-content:center;
align-items:center;
cursor:pointer;
border:none;
background:none;
`

const MessageInput = styled.input`
width:100%;
border-radius:100px;
padding:10px 16px;
border:none;
background:#D9D9D90D;
color:${colors.white};
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
&:focus{
outline:none;
}
`

const SendMessageButton = styled.button`
border-radius:100%;
display:flex;
display:flex;
min-width:42px;
max-width:42px;
width:100%;
align-items:center;
cursor:pointer;
border:none;
background:none;

&:disabled{
opacity:0.5;
cursor:inherit;
}

`