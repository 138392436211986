
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'




const MessageReceived = ({
    originalText,
    sentTime,
    translatedText,
    language,
    image,
    handleReactToMessage,
    messageId,
    conversationId,
    reactions,
    handleShowFullScreenImg,
    messageDeleted,
    displayRightClickMenu,
    selectedConversation,
    user,
}) => {

    const [showReactionButton, setShowReactionButton] = useState(false);
    const [showOriginalMessage, setShowOriginalMessage] = useState(false);

    let imageHeight = 400;
    let imageWidth = 350;

    const calculateAspect = (dimension) => {
        const ratio = Math.min(350 / image.width, 400 / image.height);
        return (dimension * ratio)
    }

    if (image) {
        imageWidth = calculateAspect(image.width);
        imageHeight = calculateAspect(image.height);
    }

    return (
        <Container >
            <MessageWrapper onContextMenu={(e) => displayRightClickMenu(e, messageId, conversationId)} >
                {selectedConversation.group &&
                    <UserNameWrapper >
                        <p>{user.name}</p>
                    </UserNameWrapper>}
                <ConversationAndOptions>
                    <ConversationMessage imgcontainer={image}>
                        {image && <img height={imageHeight} src={image.url} alt='pht' onClick={() => handleShowFullScreenImg(image.url, messageId, conversationId)} />}
                        {showOriginalMessage ? <p>{originalText}</p> : <p>{translatedText?.translation}</p>}
                        {messageDeleted && <h3><i>Message deleted</i></h3>}
                        {reactions && !messageDeleted && <ReactionContainer>{reactions[0]?.reaction}</ReactionContainer>}
                    </ConversationMessage>
                    {/* {showReactionButton && !image && !messageDeleted &&
                        <ReactionButton
                            messageId={messageId}
                            conversationId={conversationId}
                            handleReactToMessage={handleReactToMessage}
                        />} */}
                </ConversationAndOptions>
                <Details>
                    <SendTime>
                        <p>{!messageDeleted && sentTime}</p>
                    </SendTime>
                    <Status>
                        {!messageDeleted && !image ? showOriginalMessage ? <p>  See  <span onClick={() => setShowOriginalMessage(!showOriginalMessage)}>Preffered language</span></p> :
                            <p> - Translated from <span onClick={() => setShowOriginalMessage(!showOriginalMessage)}>{language}</span> </p> : ""}
                    </Status>
                </Details>
            </MessageWrapper>



        </Container>



    )
}

export default MessageReceived
const Container = styled.div`
display:flex;
align-items:center;
`
const MessageWrapper = styled.div`
display:flex;
flex-direction:column;
padding:10px 20px;
gap:7px;
position:relative;
width:fit-content;
max-width:40%;
`
const ConversationAndOptions = styled.div`
display:flex;
align-items:center;
gap:10px;
position:relative;
width:fit-content;
`

const ConversationMessage = styled.div`
display:flex;
flex-direction:column;
background:${colors.receivedMessageBg};
padding: 16px;
padding: ${(props) => props.imgcontainer ? "4px" : "16px"} ;
border-top-left-radius:${(props) => props.imgcontainer ? "4px" : "8px"} ;
border-bottom-left-radius:${(props) => props.imgcontainer ? "4px" : "20px"} ;
border-top-right-radius:${(props) => props.imgcontainer ? "4px" : "20px"} ;
border-bottom-right-radius:${(props) => props.imgcontainer ? "4px" : "20px"} ;
width:fit-content;
position:relative;
display:flex;
align-items:center;
p{
    overflow:hidden;
    text-overflow:ellipsis;
white-spaces:pre-wrap;
font-size: 16px;
font-weight: 400;
line-height: 19px;
text-align: left;
color:${colors.white};
}
h3{
     overflow:hidden;
    text-overflow:ellipsis;
white-spaces:pre-wrap;
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
color:${colors.chatElementColor}; 
padding:0 5px;
}

img{
    overflow:hidden;
    cursor:pointer;
    max-height:400px;
    max-width:350px;
}
`
const Details = styled.div`
display:flex;
gap:5px;
width:fit-content;
align-items:start;
`
const SendTime = styled.div`
display:flex;
align-items:center;

p{
font-size: 12px;
font-weight: 400;
line-height: 14px;
text-align:left;
color:#FAFAFA4D;
}
`
const Status = styled.div`
display:flex;
align-items:center;

p{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
   text-align:left;
    color:#FAFAFA4D;
}
span{
    cursor:pointer;
    font-weight:600;
    color:${colors.purple};
}
`

const ReactionContainer = styled.div`
position:absolute;
width:20px;
height:20px;
right:-5px;
bottom:-5px;
border-radius:100px;
display:flex;
align-items:center;
justify-content:center;
color:red;
`
const UserNameWrapper = styled.div`
display:flex;
justify-content:start;
width:100%;
p{
    font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
color:${colors.purple};
}
`