import { useEffect, useState } from "react";
import DashBoard from "./Pages/DashBoard";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./Router/PrivateRoute";
import LoginPage from "./Pages/LoginPage";
import SpinnerComponent from "./Components/Generic Components/SpinnerComponent";
import { Toaster } from "react-hot-toast";
import ConversationsView from "./Pages/ConversationsView";
import { useAuthService } from "./Context/AuthContext";

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const { getTranslationsLanguages } = useAuthService()

  useEffect(() => {
    getTranslationsLanguages(() => { }, () => { })
  }, [])

  return (
    <>
      <SpinnerComponent isLoading={isLoading}>
        <Toaster />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={
            <PrivateRoute>
              <DashBoard setIsLoading={setIsLoading} />
            </PrivateRoute>
          }
          />
          <Route path="/conversations" element={
            <PrivateRoute>
              <ConversationsView setIsLoading={setIsLoading} />
            </PrivateRoute>
          }
          />

        </Routes>
      </SpinnerComponent>
    </>


  );
}

export default App;
