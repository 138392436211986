
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import MessagePageNewUsers from '../MessagesComponents/MessagePageNewUsers'
import MessagePageCurrentUsers from '../MessagesComponents/MessagePageCurrentUsers'
import Switch from '@mui/material/Switch';

const MessagesOptionPage = ({ setIsLoading }) => {
    const CURRENT = "current_users"
    const NEW = "new_users"

    const [option, setOption] = useState(CURRENT);

    const handleSwitchChange = (e) => {
        let checked = e.currentTarget.checked;
        if (checked) {
            setOption(NEW)
        } else {
            setOption(CURRENT)
        }
    }

    const displayContent = () => {

        var content = <MessagePageCurrentUsers setIsLoading={setIsLoading} />

        if (option === NEW) {
            content = <MessagePageNewUsers setIsLoading={setIsLoading} />
        }

        return content
    }

    return (
        <MessagesOptionPageContainer>
            <Header>
                <SwitchWrapper>
                    <p style={option === CURRENT ? { opacity: "1" } : {}}>Current Users</p>
                    <Switch
                        onChange={(e) => handleSwitchChange(e)}
                        sx={{
                            padding: "0 6px",
                            alignItems: "center",

                            span: {
                                color: "white",
                            },

                            "& .MuiSwitch-track": {
                                height: "26px",
                                width: "100%",
                                alignItems: "center",
                            },
                            "& .MuiSwitch-thumb": {

                            },
                            "& .MuiSwitch-switchBase.Mui-checked ": {
                                transform: "translateX(20px)",
                                color: "rgba(248, 248, 248, 1)"
                            },
                            "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                borderRadius: "12px",
                                opacity: "1",
                                backgroundColor: 'rgba(112, 105, 253, 1)',

                            },
                            "& .MuiSwitch-switchBase+.MuiSwitch-track": {
                                borderRadius: "12px",
                                opacity: "1",
                                backgroundColor: 'rgba(112, 105, 253, 1)',
                            },

                        }}
                    />
                    <p style={option === NEW ? { opacity: "1" } : {}}>New Users</p>
                </SwitchWrapper>

            </Header>
            <ContentWrapper>
                {displayContent()}
            </ContentWrapper>
        </MessagesOptionPageContainer>
    )
}

export default MessagesOptionPage
const MessagesOptionPageContainer = styled.div`
display:flex;
flex-direction:column;
height:100vh;
overflow:auto;
position:relative;
background:${colors.dashBoard.bg};
`
const Header = styled.div`
display:flex;
padding:10px 50px;
background:${colors.dashBoard.headerBg};
min-height:75px;
`

const ContentWrapper = styled.div`

`

const SwitchWrapper = styled.div`
display:flex;
margin-left:auto;
align-items:center;
p{
    color:white;
    opacity:0.3;
font-size: 16px;
font-weight: 500;
line-height: 24px;

}
`