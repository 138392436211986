import { POST } from "../API";



export const logoutAdminAPI = (token, onSuccess, onError) => {

    const data = {
        token: token
    };

    POST("/oauth/revoke", data, onSuccess, onError);
};
