import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'

const PrimaryButton = ({ icon, text, ...props }) => {
    return (
        <CustomButton {...props}>
            {icon}
            {text}
        </CustomButton>
    )
}

export default PrimaryButton

const CustomButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
width:100%;
border:none;
border-radius:10px;
padding:10px;
cursor:pointer;
background:${colors.purple};
color:${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 24px;
&:disabled{
    opacity:0.3;
}
`