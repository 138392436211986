import { GET, POST } from "../API"



export const getOtpStatusAPICall = (
    email,
    onSuccess,
    onError
) => {
    const data = {
        email: email
    }
    POST(`/api/v1/identity/admins/otp_enabled`, data, onSuccess, onError, true)
}

export const getOtpQRAPICall = (
    onSuccess,
    onError
) => {

    GET(`/api/v1/identity/admins/otp_qr`, onSuccess, onError, true)
}

export const set2FAEnabledAPICall = (
    enabled,
    onSuccess,
    onError
) => {
    const data = {
        enabled: enabled
    }
    POST(`/api/v1/identity/admins/set_2fa_enabled`, data, onSuccess, onError, true)
}

