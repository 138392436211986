import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import { signInAdmin } from "../API/authentication/loginAdmin";
import LocalStorage from "../Helpers/LocalStorage";
import { logoutAdminAPI } from "../API/authentication/logoutAdmin";
import { getCurrentUserAPI } from "../API/authentication/currentUser";
import { addTranslationPairAPICall, deleteTranslationPairAPICall, getAllModelsAPICall, getPromptKeywordsAPICall, getTranslationPairAPICall, setDefaultModelAPICall, setModelPromptAPICall, setParamsAPICall } from "../API/settings/LLMService";

export const LLMContext = createContext({
    models: [],
    promtKeywords: [],
    translationPairs: [],
    getAllModels: () => { },
    setDefaultModel: () => { },
    getPrompyKeywords: () => { },
    getTranslationPairs: () => { },
    addTranslationPair: () => { },
    deleteTranslationPair: () => { },
    setPrompt: () => { },
    setTranslationParams: () => { },

});

const LLMContextProvider = ({ children }) => {
    const [models, setModels] = useState([]);
    const [promtKeywords, setPromptKeywords] = useState([]);
    const [translationPairs, setTranslationPairs] = useState([]);


    const getPrompyKeywords = useCallback(
        async (callbackFunction, callbackError) => {
            try {
                getPromptKeywordsAPICall(
                    function (response) {
                        setPromptKeywords(response.data.keywords)
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );
    const setPrompt = useCallback(
        async (id, text, callbackFunction, callbackError) => {
            try {
                setModelPromptAPICall(
                    id, text,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const getAllModels = useCallback(
        async (callbackFunction, callbackError) => {
            try {
                getAllModelsAPICall(
                    function (response) {
                        setModels(response.data.models)
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const setDefaultModel = useCallback(
        async (modelId, callbackFunction, callbackError) => {
            try {
                setDefaultModelAPICall(
                    modelId,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const getTranslationPairs = useCallback(
        async (callbackFunction, callbackError) => {
            try {
                getTranslationPairAPICall(
                    function (response) {
                        setTranslationPairs(response.data.translation_pairs)
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const addTranslationPair = useCallback(
        async (from, to, modelId, callbackFunction, callbackError) => {
            try {
                addTranslationPairAPICall(
                    from, to, modelId,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const deleteTranslationPair = useCallback(
        async (id, callbackFunction, callbackError) => {
            try {
                deleteTranslationPairAPICall(
                    id,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );

    const setTranslationParams = useCallback(
        async (id, temperature, top_k, top_p, response_length, callbackFunction, callbackError) => {
            try {
                setParamsAPICall(
                    id, temperature, top_k, top_p, response_length,
                    function (response) {
                        callbackFunction(response);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } catch (error) {
                callbackError(error);
            }
        }
    );



    return (
        <LLMContext.Provider
            value={{
                models,
                promtKeywords,
                getAllModels,
                setDefaultModel,
                getPrompyKeywords,
                translationPairs,
                getTranslationPairs,
                addTranslationPair,
                deleteTranslationPair,
                setPrompt,
                setTranslationParams,
            }}
        >
            {children}
        </LLMContext.Provider>
    );
};

export const useLlmService = () => useContext(LLMContext);

export default LLMContextProvider;
