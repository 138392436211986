import { POST } from "../API"



export const auditLogsAPI = (
    start_date,
    end_date,

    onSuccess,
    onError
) => {
    const data = {
        start_date: start_date,
        end_date: end_date
    }

    POST(`/api/v1/administration/audit_logs`, data, onSuccess, onError, true)
}