
export const sortNewestConversations = (conversations) => {
    let mapped = conversations.map((item) => {
        if (item.last_message) {
            return item
        } else {
            return {
                ...item,
                last_message: {
                    created_at: item.created_at
                }
            }
        }
    })
    return mapped?.sort((a, b) => a?.last_message?.created_at < b?.last_message?.created_at ? 1 : -1)
}

export const sortReceivedMessages = (messages) => {

    let sorted = messages?.sort((a, b) => a.device_sent_date > b.device_sent_date ? 1 : -1)
    return sorted;
}

export const handleReceivedMessages = (oldMessagesList, newMessgesList) => {

    const oldMessages = oldMessagesList;
    const newMessages = checkIsArray(newMessgesList);
    // console.log(oldMessages, "oldMessages")
    //console.log(newMessages, "newMessages")
    const oldMessagesIds = oldMessages?.map((message) => message.id)
    const newMessagesIds = newMessages?.map((message) => message.id)

    const allMessagesIds = newMessagesIds.concat(oldMessagesIds);
    // console.log(allMessagesIds, "allMessagesIds")
    const uniqueMessagesIds = [...new Set(allMessagesIds)];
    // console.log(uniqueMessagesIds, "uniqueMessagesIds")
    let newMessagesList = [];

    uniqueMessagesIds.forEach((messageId) => {

        const newMessageFound = newMessages.find((obj) => {
            return obj.id === messageId;
        })
        // console.log(newMessageFound, "newMessageFound")
        const oldMessageFound = oldMessages.find((obj) => {
            return obj.id === messageId
        })

        if (newMessageFound) {
            newMessagesList.push(newMessageFound)
        } else

            if (oldMessageFound) {
                newMessagesList.push(oldMessageFound)
            }



    })
    // console.log(newMessagesList, "newMessagesList")
    return newMessagesList;

}

const checkIsArray = (element) => {
    if (Array.isArray(element)) {
        return element
    } else {
        return [element]
    }
}


export const returnMb = (size) => {
    if (!size) {
        return
    }

    let mb = (size / 1000000)
    let kb = (size / 1000)

    if (mb.toFixed(2) <= 1.00) {
        return `${kb.toFixed(2)} Kb`
    } else {
        return `${mb.toFixed(2)} MB`
    }

}