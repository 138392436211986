import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const DateSeparator = ({ date }) => {
    return (
        <Container>
            <DateSeparatorWrapper>
                <p>{moment(date).format("dddd, DD")}</p>
            </DateSeparatorWrapper>


        </Container>
    )
}

export default DateSeparator
const Container = styled.div`
display:flex;
widht:100%;
align-items:center;
justify-content:center;
padding:10px 0;
`
const DateSeparatorWrapper = styled.div`
padding:8px 16px;
display:flex;
justify-content:center;
align-items:center;
background:#33354654;
border-radius:100px;
p{
font-size: 14px;
font-weight: 500;
line-height: 17px;
color:#F5F5F5;
}

`