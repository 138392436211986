import React from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";

const ConversationHeader = ({ selectedConversation, onClick }) => {


  const otherUser = selectedConversation?.other_participants[0];
  let profilePicture = otherUser?.profile_picture
  let name = otherUser?.name

  if (selectedConversation?.group) {
    profilePicture = selectedConversation?.group.image
    name = selectedConversation?.group.name
  }
  const getGroupMembers = (groupMembers) => {
    let members = groupMembers.map((member) => { return member.name })
    return members.join(", ")
  }

  return (
    <Container onClick={onClick}>
      {selectedConversation &&
        <Wrapper>
          <UserAvatar>
            {profilePicture ? <img src={profilePicture} alt={'av'} /> : <AvatarUnset />}
          </UserAvatar>
          <Text>
            <h1>{name}</h1>
            {selectedConversation?.group &&
              <h2>{getGroupMembers(selectedConversation.other_participants)}</h2>}
          </Text>
        </Wrapper>
      }

    </Container>
  )
}

export default ConversationHeader
const Container = styled.div`

`
const Wrapper = styled.div`
display:flex;
align-items:center;
gap:15px;
padding: 16px;
min-height:74px;
cursor:pointer;
max-height:74px;
overflow:hidden;
`
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
justify-content: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  object-fit: contain;
  position: relative;
  min-width: 42px;
  min-height:42px;
  overflow:hidden;
    svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img{
    object-fit:cover;
 position: relative;
       height:43px;
    width:43px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
overflow:hidden;
width:100%;
h1{
  text-transform:capitalize;
    color:${colors.white};
font-size: 20px;
font-weight: 600;
line-height: 20px;
text-align: left;
}

h2{
width:100%;
  overflow:hidden;
  max-height:20px;
white-space: nowrap;
    text-overflow: ellipsis;
  color:${colors.white};
  opacity:0.4;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}
`;