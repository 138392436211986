import React from "react";
import styled from "styled-components";
import {
  Menu,
  Item,
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { colors } from "../../Assets/styles/colors";



const RightClickMenu = ({ handleDeleteMessage, deleteItem, }) => {

  function handleItemClick({ id }) {
    switch (id) {
      case "remove":
        handleDeleteMessage(deleteItem.messageId);
        break;
      default:
        break;
    }
  }

  return (
    <Container>
      <StyledMenu id={"menu-id"} className="styledMenu">
        <Item onClick={handleItemClick} id="remove" className="delet_item">
          Delete
        </Item>
      </StyledMenu>
    </Container>
  );
};

export default RightClickMenu;
const Container = styled.div`
  .styledMenu {
    background: ${colors.newChatModalBg};
    color: white;
  }
`;

const CustomItem = styled.div`
  background: red;
  padding: 5px;
  border-radius: 5px;
  color: ${colors.errorColor};
  background: transparent;
  cursor: pointer;
  &:hover {
    background: ${colors.purple};
  }
`;
const StyledMenu = styled(Menu).attrs({
  // custom props
})`
  .contexify_item {
    color: ${colors.errorColor};
  }
  .contexify_itemContent {
    color: ${colors.errorColor};
  }
  .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent, .contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent {
    color: inherit;
    background-color: ${colors.purple};

  }
`;
