import { GET, POST } from "../API"
let source;

export const getMaxFileSizeAPICall = (
    onSuccess,
    onError
) => {
    source = GET(`/api/v1/administration/settings_max_file_size`, onSuccess, onError, true, source)
}

export const setMaxFileSizeAPICall = (
    maxFileSize,
    onSuccess,
    onError
) => {
    const data = {
        max_file_size: maxFileSize
    }
    POST(`/api/v1/administration/settings_max_file_size`, data, onSuccess, onError, true, source)
}