import { POST } from "../API"


let source;
export const searchUserAPI = (
    term,
    onSuccess,
    onError
) => {
    const data = {
        term: term
    }

    source = POST(`/api/v1/administration/search_user`, data, onSuccess, onError, true, source)
}