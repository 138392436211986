class LocalStorage {
    static setAuthenthicationToken(access_token) {
        localStorage.setItem("emotii_bo_access_token", access_token);
    }

    static getAuthenticationToken() {
        return localStorage.getItem("emotii_bo_access_token");
    }

    static setRefreshToken(refresh_token) {
        localStorage.setItem("emotii_bo_refresh_token", refresh_token);
    }

    static getRefreshToken() {
        return localStorage.getItem("emotii_bo_refresh_token");
    }


}

export default LocalStorage;
